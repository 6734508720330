import * as React from "react";
const IndiceIcona = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Livello_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 85 85"
    }}
    viewBox="0 0 85 85"
    {...props}
  >
    <path
      d="M54 23v46L35.6 55.2 17.2 68.9V23c0-3.8 3.1-6.9 6.9-6.9h36.8"
      className="cls-1"
    />
    <path
      d="M60.9 16.1h0c3.8 0 6.9 3.1 6.9 6.9v13.8H54V23c0-3.8 3.1-6.9 6.9-6.9z"
      className="cls-1"
    />
  </svg>
);
export default IndiceIcona;
