import { serverResponse } from "../util/fakeServer";
import DefaultManager from "./Default.manager";

class ErgonManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getAnalyticsId() {
    switch (this.signboardId) {
      case 46:
        return "G-FE092Q4D3B";

      default:
        return serverResponse.config.ga_tracking_id;
    }
  }

  getBaseWidth() {
    return "0rem";
  }

  showList() {
    return false;
  }
}

export default ErgonManager;
