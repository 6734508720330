import * as React from "react"
const ArrowRx = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 2"
    viewBox="0 0 19.96 36.85"
    {...props}
  >
    <path
      d="M.77 36.09 19.2 18.43.77.77"
      data-name="Livello 1"
      className="cls-1"
    />
  </svg>
)
export default ArrowRx
