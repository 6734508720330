import * as React from "react";
const ZoomOut = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Livello_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 85.04 85.04"
    }}
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <circle cx={36.81} cy={36.82} r={20.55} className="cls-1" />
    <path
      d="M67.77 67.76h0a3.416 3.416 0 0 1-4.84 0l-8.07-8.07a3.416 3.416 0 0 1 0-4.84h0a3.416 3.416 0 0 1 4.84 0l8.07 8.07a3.416 3.416 0 0 1 0 4.84zM55.07 55.08l-4.56-4.56M47.81 36.82h-22"
      className="cls-1"
    />
  </svg>
);
export default ZoomOut;
