import * as React from "react";

const Pdf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <path
      d="M50.27 30h11.42L48 16.26v11.42A2.28 2.28 0 0 0 50.27 30ZM23.35 34.83V18.54a2.29 2.29 0 0 1 2.29-2.28H48"
      className="cls-1"
    />
    <path
      d="M61.69 30v36.5a2.29 2.29 0 0 1-2.29 2.28H25.64a2.29 2.29 0 0 1-2.29-2.28V55.67"
      className="cls-1"
    />
    <path
      d="M23.69 41.72a2.41 2.41 0 0 0-1.32-.89 10.73 10.73 0 0 0-2.19-.14h-2.95v9.12h1.84v-3.44h1.2a10.22 10.22 0 0 0 1.91-.13 2.64 2.64 0 0 0 1-.43 2.53 2.53 0 0 0 .77-.9 3 3 0 0 0 .31-1.41 2.85 2.85 0 0 0-.57-1.78Zm-1.58 2.51a1.25 1.25 0 0 1-.58.45 4.94 4.94 0 0 1-1.45.14h-1v-2.59H20a8.82 8.82 0 0 1 1.33.07 1.32 1.32 0 0 1 .74.4 1.3 1.3 0 0 1 .08 1.53ZM33.07 43.18a4 4 0 0 0-.87-1.47 2.91 2.91 0 0 0-1.38-.84 6.33 6.33 0 0 0-1.73-.18h-3.37v9.12h3.47a5.55 5.55 0 0 0 1.63-.19 3.33 3.33 0 0 0 1.29-.73 4.1 4.1 0 0 0 1-1.61 6.16 6.16 0 0 0 .28-1.94 6.74 6.74 0 0 0-.32-2.16Zm-1.79 3.75a2 2 0 0 1-.49.88 1.68 1.68 0 0 1-.74.38 5 5 0 0 1-1.11.08h-1.38v-6h.83a8.09 8.09 0 0 1 1.51.09 1.76 1.76 0 0 1 .85.43 2.11 2.11 0 0 1 .53.88 5.68 5.68 0 0 1 .18 1.63 6.19 6.19 0 0 1-.18 1.63ZM41.19 42.23v-1.54h-6.25v9.12h1.84v-3.87h3.81v-1.55h-3.81v-2.16h4.41z"
      className="cls-1"
    />
    <rect
      width={32.67}
      height={20.84}
      x={12.88}
      y={34.83}
      className="cls-1"
      rx={2.35}
    />
  </svg>
);
export default Pdf;
