import React, { Fragment, useState } from "react";
import SidebarWrapper from "./sidebar.style";
import Radium from "radium";
import NavList from "components/sidebar/NavList";
import { sidebarData } from "util/data/sidebar";
import { interattivoIcon, iconAppName } from "helper/constant";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ShareFlyer from "../share/ShareFlyer";
import { isIE } from "react-device-detect";

import { serverResponse } from "util/fakeServer";
import { connect } from "react-redux";
import { setIndexCarousel } from "../../redux/carousel/action";

// import RetrieveCart from "../RetrieveCart";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import { IndiceIcona } from "../../assets/images/icone";
import ManagerFactory from "../../managers/ManagerFactory";
const manager = ManagerFactory.getManager();
const IndexNav = (props) => {
  const [isActive, toggleActive] = useState(false);

  const routerLinkLeaflet =
    global.preview_id !== undefined
      ? `/leaflet?id=${global.preview_id}`
      : "/leaflet";

  let indexClass = isActive
    ? "nav-link main-list active"
    : "nav-link main-list";

  return (
    <Fragment>
      {manager.showList() && (
        <li
          className={props.mini === "on" ? "li_sidebar" : ""}
          id="indexIconSidebar"
        >
          <Link
            to={routerLinkLeaflet}
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setIndex();
            }}
            onMouseDown={() => toggleActive(!isActive)}
            onMouseUp={() => toggleActive(!isActive)}
            className={indexClass}
          >
            <IndiceIcona />
            <p
              style={{
                opacity: props.mini === "on" ? null : "1",
                visibility: "visible"
              }}
              className={props.mini ? "fade_p" : ""}
            >
              {language[defaultLanguage].indice}
            </p>
          </Link>
        </li>
      )}
    </Fragment>
  );
};

const Sidebar = (props) => {
  let listNameStyle;
  let sidebar;
  let appNameStyle = {};

  const {
    mini,
    drawerWidth,
    miniDrawerWidth,
    sidebarTheme,
    closeDrawer,
    themeSetting
  } = props;

  let redirect = "https://www.volantinointerattivo.net/";
  if (serverResponse.config.custom_link)
    redirect = serverResponse.config.custom_link;

  if (themeSetting.sidebarTransParentValue === "on") {
    sidebar = {
      backgroundImage: `linear-gradient(0deg,rgba(0, 0, 0, 0.8),rgba(0, 0, 0, 0.9)),url(${themeSetting.transparentImage})`,
      backgroundRepeat: "no-repeat, repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      width: mini ? miniDrawerWidth : drawerWidth,
      "@media (max-width: 991px)": {
        width: mini ? 0 : drawerWidth
      }
    };
  } else {
    sidebar = {
      width: mini ? miniDrawerWidth : drawerWidth,
      background: sidebarTheme.backgroundColor,
      "@media (max-width: 991px)": {
        width: mini ? 0 : drawerWidth
      }
    };
  }

  const closeIcon = {
    "@media (max-width: 991px)": {
      display: "block"
    }
  };

  if (mini) {
    listNameStyle = {
      opacity: miniDrawerWidth === drawerWidth ? 1 : 0,
      transform:
        miniDrawerWidth === drawerWidth
          ? "translateZ(0)"
          : "translate3d(-25px,0,0)"
    };
    if (isIE) {
      appNameStyle = {
        height: miniDrawerWidth === drawerWidth ? "" : "0px"
      };
    }
  } else {
    listNameStyle = {
      opacity: !mini ? 1 : 0,
      transform: !mini ? "translateZ(0)" : "translate3d(-25px,0,0)"
    };
    if (isIE) {
      appNameStyle = {
        height: !mini ? "" : "0px"
      };
    }
  }
  return (
    <SidebarWrapper
      className="d-none d-lg-block"
      themeSetting={themeSetting}
      sidebarTheme={sidebarTheme}
      mini={mini}
      miniDrawerWidth={miniDrawerWidth}
      drawerWidth={drawerWidth}
    >
      <div id="sidebar" className="sidebar sideBack shadow" style={sidebar}>
        <div className="sidebar-header d-none px-0 mb-3 ">
          <NavLink
            to={"/"}
            className="simple-text logo-mini"
            onClick={() => {
              if (!serverResponse.config.no_link_to_site)
                window.open(redirect, "_blank");
            }}
          >
            <div className="logo-img">
              <img
                src={interattivoIcon}
                alt="rvolantino interattivo"
                style={{ width: "45px", height: "auto" }}
              />
            </div>
          </NavLink>

          {!serverResponse.config.no_link_to_site && (
            <a href={redirect} rel="noopener noreferrer" target="_blank">
              <div
                className="logo-text simple-text custom-sidebar-header"
                style={appNameStyle}
              >
                <img
                  src={iconAppName}
                  className={
                    serverResponse.config.small_logo
                      ? "logo_small"
                      : "logo_full"
                  }
                  alt="react-logo"
                />
              </div>
            </a>
          )}
        </div>
        <div
          className="close-drawer-icon"
          style={closeIcon}
          onClick={closeDrawer()}
        >
          <i className="fas fa-times-circle" />
        </div>

        <div className="sidebar-wrapper">
          <ul className="nav mtop-5">
            {serverResponse.leaflet.index !== null && (
              <IndexNav
                mini={props.themeSetting}
                setIndex={() => props.setIndexCarousel(0)}
                listNameStyle={listNameStyle}
              />
            )}

            {sidebarData.map((list, i) => {
              return (
                <Fragment key={i}>
                  {list && list.type && list.type === "heading" ? (
                    (!mini || miniDrawerWidth === drawerWidth) && (
                      <div className="sidelist-header-name">{list.name}</div>
                    )
                  ) : (
                    <NavList
                      listNameStyle={listNameStyle}
                      list={list}
                      mini={mini}
                      miniDrawerWidth={miniDrawerWidth}
                      drawerWidth={drawerWidth}
                      forceDrawerMini={props.forceDrawerMini}
                      {...props}
                    />
                  )}
                </Fragment>
              );
            })}
            {serverResponse.hasOwnProperty("video_categories") && (
              <li>
                <button className="nav-link main-list">
                  <i className="fas fa-play-circle"></i>
                  <p style={listNameStyle}>
                    {language[defaultLanguage].video_tutorial}
                  </p>
                </button>
              </li>
            )}
            {serverResponse.config.sidebar.includes("share") &&
              manager.showList() && (
                <ShareFlyer
                  forceDrawerMini={props.forceDrawerMini}
                  pStyle={listNameStyle}
                />
              )}
          </ul>
          <p
            style={{
              position: "absolute",
              bottom: "0px",
              fontSize: "9px",
              width: "100%",
              textAlign: "center",
              fontWeight: "bold",
              color: "#c7c7c7"
            }}
          >
            v{process.env.REACT_APP_VERSION}
          </p>
          {/* <SearchNav mini={props.themeSetting} /> */}
          {/* <div style={{position: "absolute", bottom: "0px"}}>Powered by Luigi D'Arco</div> */}
        </div>
      </div>
    </SidebarWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    themeSetting: state.themeSetting.sidebarMiniValue
  };
};

export default withRouter(
  connect(mapStateToProps, { setIndexCarousel })(Radium(Sidebar))
);
