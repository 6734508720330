import * as React from "react"
const Close_ = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 184.95 184.95"
  >
    <circle cx={92.47} cy={92.47} r={91.47} className="cls-1" />
    <path
      d="m50.61 50.61 83.72 83.72M134.33 50.61l-83.72 83.72"
      className="cls-1"
    />
  </svg>
)
export default Close_
