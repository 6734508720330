import * as React from "react";
import { serverResponse } from "../../../util/fakeServer";
const InfoSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <path
      d="M33.66 33.58h5.83c3.34 0 10.34 0 15.17-2.08L44.57 65.94a9.09 9.09 0 0 0-.34 2c0 1.08.42 1.67 1.17 1.67 1.33 0 6-1.76 8.34-5.59l2.34.83c-2.92 5.5-11.1 9.26-17.43 9.26-4.5 0-8.92-1.92-8.92-7.84 0-1.83.83-5.42 2.08-10L37.23 37h-5.17Zm15-21.69a7.51 7.51 0 1 1-7.5 7.51 7.47 7.47 0 0 1 7.49-7.51Z"
      style={{
        fill: "none",
        stroke: serverResponse.config.primary_color,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      }}
    />
  </svg>
);
export default InfoSvg;
