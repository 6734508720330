import React from "react";
// import "../App.css";
import { styles } from "./readMore.style";

const ReadMore = ({ children, limit = 15000 }) => {
  const text = children;
  // const [isReadMore, setIsReadMore] = useState(true);
  // const toggleReadMore = () => {
  //   setIsReadMore(!isReadMore);
  // };
  return (
    <p style={styles.text}>
      {text}
      {/* {text.length > limit && (
        <span onClick={toggleReadMore} style={styles.read_or_hide}>
          {isReadMore ? "▼ Leggi tutto" : "▲ Leggi meno"}
        </span>
      )} */}
    </p>
  );
};

export default ReadMore;
