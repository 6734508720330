import * as React from "react"
const ArrowLx = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 2"
    viewBox="0 0 19.96 36.85"
    {...props}
  >
    <path
      d="M19.2.77.77 18.43 19.2 36.09"
      data-name="Livello 1"
      className="cls-1"
    />
  </svg>
)
export default ArrowLx
