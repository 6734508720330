import {
  LOGIN_JWT_SUCCESS,
  TOGGLE_JWT_MODAL,
  LOGIN_JWT_FAIL,
  START_JWT_LOADING,
  GET_JWT_PROFILE,
  GET_JWT_PROFILE_FAIL,
  TOGGLE_USER_MODAL,
  LOGOUT_JWT,
  SUCCESS_SIGNUP_JWT,
  REGISTER_JWT_FAIL,
  END_JWT_LOADING
} from "../type";
import * as services from "../../services/authJWT.services";
import { serverResponse } from "../../util/fakeServer";
import { messageError } from "../../helper/utility";

export const loginJWT = (data, history) => (dispatch) => {
  dispatch({
    type: START_JWT_LOADING
  });
  const apiData = {
    ...data,
    signboard_id: serverResponse.config.signboard_id
  };
  services
    .login(apiData)
    .then((res) => {
      if (res) {
        const {
          data: { status, data: payload }
        } = res;

        if (status === 200) {
          dispatch({
            type: LOGIN_JWT_SUCCESS,
            payload
          });
          // window.parent.postMessage(
          //   payload.id,
          //   "https://8b8d-5-158-70-31.ngrok-free.app"
          // );

          if (history) return history.push("/leaflet");
        } else {
          dispatch({
            type: LOGIN_JWT_FAIL,
            payload: messageError(status)
          });
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_JWT_FAIL,
        payload: messageError(err && err.status)
      });
    });
};

export const signupJWT = (data) => (dispatch) => {
  dispatch({
    type: START_JWT_LOADING
  });
  const apiData = {
    ...data,
    signboard_id: serverResponse.config.signboard_id,
    id_client: serverResponse.config.client_id,
    // id_pv: 10,
    landing: window.location.href
  };
  services
    .signup(apiData)
    .then((res) => {
      if (res) {
        const {
          data: { status }
        } = res;

        if (status === 200) {
          return dispatch({
            type: SUCCESS_SIGNUP_JWT
          });
        } else {
          dispatch({
            type: REGISTER_JWT_FAIL,
            payload: messageError(status)
          });
        }
      }
    })
    .catch((err) => {
      console.log("THIS IS ERR", err);
      dispatch({
        type: REGISTER_JWT_FAIL,
        payload: messageError(err && err.status)
      });
    });
};

export const startLoadingJWT = () => (dispatch) => {
  dispatch({
    type: START_JWT_LOADING
  });
};

export const endLoadingJWT = () => (dispatch) => {
  dispatch({
    type: END_JWT_LOADING
  });
};

export const getProfileJWT = () => (dispatch) => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  const code = urlParams.get("code");
  let splitParam = "";

  if (code) {
    splitParam = code.split("?")[0];
  }

  services
    .getProfile(splitParam)
    .then((res) => {
      if (res) {
        const {
          data: { status, data: payload }
        } = res;

        if (status === 200) {
          dispatch({
            type: GET_JWT_PROFILE,
            payload
          });

          console.log(payload);
          // window.parent.postMessage(
          //   payload.id,
          //   "https://8b8d-5-158-70-31.ngrok-free.app"
          // );
        } else {
          dispatch({
            type: GET_JWT_PROFILE_FAIL
            // payload: messageError()
          });

          // if (serverResponse.config.only_logged) {
          //   if (allowPath.includes(window.location.pathname)) {
          //     dispatch(push(window.location.pathname));
          //   } else {
          //     // dispatch(push("/login"));
          //   }
          // } else {
          //   dispatch(push("/leaflet"));
          // }
        }
      }
    })
    .catch((err) => {
      dispatch({
        type: LOGIN_JWT_FAIL,
        payload: messageError()
      });
      // if (serverResponse.config.only_logged) {
      //   push("/login");
      // }
    });
};

export const logoutJWT = (history) => (dispatch) => {
  services
    .logout()
    .then((data) => {
      dispatch({
        type: LOGOUT_JWT
      });
      serverResponse.config.only_logged && history.push("/login");
    })
    .catch((err) => {
      dispatch({
        type: LOGOUT_JWT
      });
    });
};

export const toggleJWTModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_JWT_MODAL
  });
};

export const toggleUserModal = () => (dispatch) => {
  dispatch({
    type: TOGGLE_USER_MODAL
  });
};
