import * as React from "react";
const VideoIperbimbo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111 111" {...props}>
    <defs>
      <style>{".cls-3{fill:#7a983a}"}</style>
    </defs>
    <title>{"Risorsa 49"}</title>
    <g id="Livello_2" data-name="Livello 2">
      <g id="Livello_1-2" data-name="Livello 1">
        <circle
          cx={55.5}
          cy={55.5}
          r={55.5}
          style={{
            fill: "#96bd0d"
          }}
        />
        <circle
          cx={55.5}
          cy={55.5}
          r={50.69}
          style={{
            fill: "#fff",
            stroke: "#96bd0d"
          }}
        />
        <rect
          width={44}
          height={36}
          x={24.5}
          y={37.5}
          className="cls-3"
          rx={4.84}
          style={{
            stroke: "#96bd0d"
          }}
        />
        <path
          d="m71.8 56.8 11.57 11.53A1.84 1.84 0 0 0 86.5 67V44a1.84 1.84 0 0 0-3.13-1.3L71.8 54.2a1.83 1.83 0 0 0 0 2.6Z"
          className="cls-3"
          style={{
            stroke: "#96bd0d"
          }}
        />
      </g>
    </g>
  </svg>
);
export default VideoIperbimbo;
