import * as React from "react";
const AddLista = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <path d="M39.61 40.87h18.37" className="cls-1" />
    <circle cx={30.02} cy={40.87} r={3.83} className="cls-1" />
    <path d="M39.61 54.29h5.16" className="cls-1" />
    <circle cx={30.02} cy={54.29} r={3.83} className="cls-1" />
    <path
      d="M31.27 28.77H18.78l15-15v12.5a2.49 2.49 0 0 1-2.51 2.5Z"
      className="cls-1"
    />
    <path
      d="M66.26 43.38v25.38a2.5 2.5 0 0 1-2.5 2.5H21.28a2.5 2.5 0 0 1-2.5-2.5v-40M33.77 13.78h30a2.5 2.5 0 0 1 2.5 2.5v16.59"
      className="cls-1"
    />
    <path
      d="m50.08 59.38-9.72 4.41 4.42-9.72 21.21-21.2 5.3 5.3-21.21 21.21zM67.14 42.32l-5.33-5.3M50.07 59.39l-5.3-5.32"
      className="cls-1"
    />
    <circle
      cx={68.63}
      cy={17.41}
      r={11.41}
      style={{
        fill: "#009640"
      }}
    />
    <path d="M62.22 17.41h12.81M68.63 11.01v12.81" className="cls-1" />
  </svg>
);
export default AddLista;
