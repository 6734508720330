import React from "react";
import StoreModal from "../components/modal/modalbody/components/Store.modal";
import DefaultManager from "./Default.manager";

class Sole365Manager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  filterMarkers() {
    return "world";
  }

  getStoreButton(link) {
    return <StoreModal link={link} />;
  }

  getRowClass(type) {
    return type === "play"
      ? "flex-column align-items-center video-button-store"
      : "";
  }

  linkToShop() {
    return true;
  }

  getShopUrl(markers) {
    for (let i = 0; i < markers.length; i++) {
      if (markers[i].type === "world") return markers[i].data;
    }
    return "https://volantinointerattivo.net";
  }
}

export default Sole365Manager;
