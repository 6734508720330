import { serverResponse } from "../../util/fakeServer";

export const styles = {
  logo_header: {
    height: "100%"
  },
  p_style: {
    fontSize: "5px",
    marginLeft: "25px",
    color: "black"
  },
  spinner_grow: {
    width: "50px",
    height: "50px",
    color: serverResponse.config.secondary_color
  },
  shopping_cart: {
    fontSize: "25px",
    color: "var(--primary-color)"
  },
  new_list_image: { cursor: "pointer", width: "35px" },
  mobile_grocery_notification: { position: "absolute" },
  link_decoration: {
    textDecoration: "none"
  }
};
