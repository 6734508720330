import {
  OPEN_INTERACTIVITY_MODAL,
  CLOSE_INTERACTIVITY_MODAL,
  OPEN_RELATED,
  OPEN_INTERACTIVITY_MODAL_STATIC,
  START_LOADING_MODAL,
  END_LOADING_MODAL,
  RESET_MODAL_DATA,
  SET_CURRENT_MARKER
} from "../type";

const initState = {
  isModalOpen: false,
  contentModal: {},
  isProduct: false,
  // staticContent: {},
  otherProps: {},
  loading: false,
  controller: new AbortController()
};

export default function(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case START_LOADING_MODAL:
      return {
        ...state,
        loading: true,
        isModalOpen: true
      };
    case END_LOADING_MODAL:
      return {
        ...state,
        loading: false
      };
    case OPEN_INTERACTIVITY_MODAL_STATIC:
    case OPEN_INTERACTIVITY_MODAL:
      return {
        ...state,
        otherProps: payload.otherProps,
        contentModal: payload.info,
        // staticContent: payload.info,
        loading: false
      };
    case OPEN_RELATED:
      return {
        ...state,
        contentModal: payload.content
      };
    case RESET_MODAL_DATA:
      return initState;
    case CLOSE_INTERACTIVITY_MODAL:
      return initState;
    case SET_CURRENT_MARKER:
      return {
        ...state,
        isProduct: payload
      };
    default:
      return state;
  }
}
