import * as React from "react";
const ListaSpesa = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Livello_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 85.04 85.04"
    }}
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <path d="M39.61 40.87h18.37" className="cls-1" />
    <circle cx={30.02} cy={40.87} r={3.83} className="cls-1" />
    <path d="M39.61 54.29h5.16" className="cls-1" />
    <circle cx={30.02} cy={54.29} r={3.83} className="cls-1" />
    <path
      d="M31.27 28.77h-12.5l14.99-14.99v12.5a2.478 2.478 0 0 1-2.49 2.49z"
      className="cls-1"
    />
    <path
      d="M66.26 43.38v25.38a2.5 2.5 0 0 1-2.5 2.5H21.28a2.5 2.5 0 0 1-2.5-2.5V28.77M33.77 13.78h29.99a2.5 2.5 0 0 1 2.5 2.5v16.59"
      className="cls-1"
    />
    <path
      d="m50.08 59.38-9.72 4.41 4.42-9.72 21.21-21.2 5.3 5.3zM67.14 42.32l-5.33-5.3M50.07 59.39l-5.3-5.32"
      className="cls-1"
    />
  </svg>
);
export default ListaSpesa;
