import * as React from "react"
const Mappa = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path
      d="M23.1 36.78h-2.29l-4.57 31.99 13.71-6.86 12.57 5.57 12.57-5.57 13.71 6.86-4.57-31.99h-2.29"
      className="cls-1"
    />
    <g id="pin">
      <path
        d="M58.12 31.88c0 11.25-15.6 27-15.6 27s-15.62-15.68-15.6-27a15.6 15.6 0 1 1 31.2 0Z"
        className="cls-1"
      />
      <path
        d="M49.61 31.35a7.09 7.09 0 1 1-7.09-7.09 7.09 7.09 0 0 1 7.09 7.09Z"
        className="cls-1"
      />
    </g>
  </svg>
)
export default Mappa
