import React, { Component } from "react";
// import { connect } from 'react-redux';
import CustomIndice from "../components/leaflet/CustomIndice";
import { serverResponse } from "../util/fakeServer";
// import { gaEventLabel } from '../settings/gaConfig';

import { Helmet } from "react-helmet";

class Indice extends Component {
  state = {
    leaflet: {},
    setPageIndex: null,
    item_id: null,
    meta_tags: <Helmet></Helmet>
  };

  // componentDidMount() {
  //   if (serverResponse.config.ga_active) {
  //     ReactGA.pageview(
  //       `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/volantino`
  //     );
  //   }
  // }

  componentWillMount() {
    let go_to_page = this.props.match.params.index;
    if (this.props.match.params.item_id) {
      let item_id = this.props.match.params.item_id;
      let pages = serverResponse.leaflet.pages.filter(
        (page) =>
          page.products.filter((prod) => prod.item_id === item_id).length > 0
      );
      if (pages.length > 0) {
        let product = pages[0].products.filter(
          (prod) => prod.item_id === item_id
        )[0];
        go_to_page = pages[0].number;
        this.setState({
          meta_tags: (
            <Helmet>
              <meta property="og:title" content={product.description}></meta>
              <meta
                property="og:url"
                content={
                  serverResponse.config.shareFlyerURL +
                  "/products/" +
                  product.item_id
                }
              ></meta>
              <meta
                property="og:description"
                content={serverResponse.config.shareProductSentence}
              ></meta>
              <meta
                property="og:image"
                content={product.images[0].image_file}
              ></meta>
              <meta property="og:type" content="website"></meta>
            </Helmet>
          )
        });
      }
    }
    // this.setState({ setPageIndex: this.props.match.params.index })
    this.setState({ setPageIndex: go_to_page });
  }

  render() {
    let tmp = Object.assign({}, serverResponse);

    const { setPageIndex } = this.state;

    return (
      <div>
        {this.state.meta_tags}
        <CustomIndice leaflet={tmp.leaflet} setPageIndex={setPageIndex} />
      </div>
    );
  }
}

export default Indice;
