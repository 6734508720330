import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";
import { H_COMPONENTS } from "../../helper/constant";

const slot_desktop = "9809224640";
const slot_mobile = "2558048876";
const googleAdId = "ca-pub-3056222019133556";
class AdComponent extends Component {
  googleInit = null;

  componentDidMount() {
    const { timeout } = this.props;
    this.googleInit = setTimeout(() => {
      if (typeof window !== "undefined")
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, timeout);
  }

  componentWillUnmount() {
    if (this.googleInit) clearTimeout(this.googleInit);
  }

  render() {
    const { position } = this.props;

    return (
      <div
        id="adsense_container"
        style={{
          height: `${H_COMPONENTS.adsense}px`,
          width: "100%",
          position: position,
          bottom: 0,
          left: 0,
          display: "flex",
          justifyContent: "center"
        }}
      >
        <ins
          className="adsbygoogle"
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center"
          }}
          data-ad-client={googleAdId}
          data-ad-slot={isMobile ? slot_mobile : slot_desktop}
          // data-ad-format="auto"
          data-full-width-responsive="true"
          data-adtest="on"
        ></ins>
      </div>
    );
  }
}

AdComponent.propTypes = {
  timeout: PropTypes.number,
  position: PropTypes.string
};

AdComponent.defaultProps = {
  timeout: 200,
  position: "inherit"
};

export default AdComponent;
