import {
  OPEN_INTERACTIVITY_MODAL,
  CLOSE_INTERACTIVITY_MODAL,
  OPEN_RELATED,
  SET_ERROR_MODAL,
  OPEN_INTERACTIVITY_MODAL_STATIC,
  START_LOADING_MODAL,
  END_LOADING_MODAL,
  SET_CURRENT_MARKER
} from "../type";
import * as services from "../../services/product.services";

let controller;

export const openInteractivityModal = (obj, id_code) => async (dispatch) => {
  const isDynamic = obj.dynamic_info;

  dispatch({
    type: SET_CURRENT_MARKER,
    payload: obj.info.markers.filter((el) => el.type === "plus").length > 0
  });

  dispatch({
    type: START_LOADING_MODAL
  });

  if (isDynamic && obj.type === "plus") {
    controller = new AbortController();
    const signal = controller.signal;
    try {
      const {
        data: { data }
      } = await services.getProduct(id_code, signal);

      dispatch({
        type: OPEN_INTERACTIVITY_MODAL,
        payload: {
          info: data[0],
          otherProps: obj
        }
      });
      dispatch({
        type: END_LOADING_MODAL
      });
    } catch (error) {
      if (error.name !== "AbortError")
        dispatch({
          type: SET_ERROR_MODAL,
          payload: error
        });
      dispatch({
        type: END_LOADING_MODAL
      });
    }
  } else {
    dispatch({
      type: OPEN_INTERACTIVITY_MODAL_STATIC,
      payload: {
        info: obj.info,
        otherProps: obj
      }
    });
  }
};

export const openRelated = (content) => (dispatch) => {
  dispatch({
    type: OPEN_RELATED,
    payload: content
  });
};

export const closeInteractivityModal = () => (dispatch) => {
  if (controller) {
    controller.abort();
  }
  dispatch({
    type: CLOSE_INTERACTIVITY_MODAL
  });
};
