import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { serverResponse } from "../util/fakeServer";
import { useLocation } from "react-router-dom";
import { allowPath } from "../helper/utility";

const PrivateRoute = ({
  component: Component,
  authJWT: { isAuthenticated, loadingProfile },
  ...rest
}) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={() =>
        serverResponse.config.allow_login &&
        serverResponse.config.only_logged &&
        !isAuthenticated &&
        loadingProfile &&
        !allowPath.includes(location.pathname) ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: location.pathname }
            }}
          />
        ) : (
          <Component {...rest} />
        )
      }
    />
  );
};
PrivateRoute.propTypes = {
  authJWT: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  authJWT: state.authJWT
});
export default connect(mapStateToProps)(PrivateRoute);
