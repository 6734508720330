import Carrello from "./Carrello.icona";
import Categorie from "./Categorie.icona";
import Close_ from "./Close_.icona";
import Close from "./Close.icona";
import Condividi from "./Condividi.icona";
import Informazioni from "./Informazioni.icona";
import Mappa from "./Mappa.icona";
import Pagine from "./Pagine.icona";
import Pdf from "./Pdf.icona";
import Prodotti from "./Prodotti.icona";
import Ricetta from "./Ricetta2.icona";
import SchermoIntero from "./SchermoIntero.icona";
import Video from "./Video.icona";
import Volantino from "./Volantino.icona";
import Plus from "./Plus.icona";
import ArrowLx from "./ArrowLx.icona";
import ArrowsLx from "./ArrowsLx.icona";
import ArrowRx from "./ArrowRx.icona";
import ArrowsRx from "./ArrowsRx.icona";
import ZoomIn from "./ZoomIn.icona";
import ZoomOut from "./ZoomOut.icona";
import ListaSpesa from "./ListaSpesa.icona";
import Search from "./Search.icona";
import AddCarrello from "./AddCarrello.icona";
import AddLista from "./AddLista.icona";
import Allegato from "./Allegato.icona";
import CondividiNew from "./CondividiNew.icona";
import Curiosita from "./Curiosita.icona";
import Curiosita2 from "./Curiosita2.icona";
import EliminaCarrello from "./EliminaCarrello.icona";
import EliminaLista from "./EliminaLista.icona";
import LinkEsterno from "./LinkEsterno.icona";
import LinkInterno from "./LinkInterno.icona";
import Negozio from "./Negozio.icona";
import Ricetta2 from "./Ricetta2.icona";
import SchedaTecnica from "./SchedaTecnica.icona";
import Video2 from "./Video2.icona";
import Prodotto from "./Prodotto.icona";
import ProdottoMano from "./ProdottoMano.icona";
import Carosello from "./Carosello.icona";
import IndiceIcona from "./Indice.icona";

export {
  Carrello,
  Categorie,
  Close_,
  Close,
  Condividi,
  Informazioni,
  Mappa,
  Pagine,
  Pdf,
  Prodotti,
  Ricetta,
  SchermoIntero,
  Video,
  Volantino,
  Plus,
  ArrowLx,
  ArrowRx,
  ArrowsLx,
  ArrowsRx,
  ZoomIn,
  ZoomOut,
  ListaSpesa,
  Search,
  AddCarrello,
  AddLista,
  Allegato,
  CondividiNew,
  Curiosita,
  Curiosita2,
  EliminaCarrello,
  EliminaLista,
  LinkEsterno,
  LinkInterno,
  Negozio,
  Ricetta2,
  SchedaTecnica,
  Video2,
  Prodotto,
  ProdottoMano,
  Carosello,
  IndiceIcona
};
