export const styles = {
  sidebar: (color, width) => {
    return {
      width: width,
      backgroundColor: color
    };
  },
  img_close_wrapper: {
    display: "flex",
    justifyContent: "center"
  },
  img_open_wrapper: {
    display: "flex",
    justifyContent: "start"
  },
  icon_list_wrapper: {
    width: "60px",
    display: "flex",
    justifyContent: "flex-end"
  },
  link_title: {
    width: "100%",
    textAlign: "left"
  },
  logo_full: {
    width: "50%",
    display: "inline"
  },
  link_scroll: (height, overflowY = "hidden") => {
    return {
      height: `${height}px`,
      overflowY: overflowY,
      overflowX: "hidden",
      textAlign: "-webkit-left"
    };
  },
  link_container: (linkColor) => {
    return {
      backgroundColor: "white",
      border: `1px solid ${linkColor}`,
      borderRadius: "4px",
      padding: "5px",
      cursor: "pointer",
      height: "35px"
    };
  }
};
