import * as React from "react";
const Prodotto = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff"
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#009fe3"
      }}
    />
    <circle cx={42.52} cy={42.52} r={17.66} className="cls-3" />
    <path d="M31 42.52h23.04M42.52 30.74v24.57" className="cls-3" />
  </svg>
);
export default Prodotto;
