import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const Ricetta2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff"
      }}
    />
    <g id="chef_hat" data-name="chef hat">
      <path
        d="M54.75 44.46a9.41 9.41 0 0 0 0-18.81 9.29 9.29 0 0 0-2.8.47 10.33 10.33 0 0 0-18.86 0 9.29 9.29 0 0 0-2.8-.47 9.41 9.41 0 1 0 8.07 14.17"
        className="cls-3"
      />
      <path
        d="m56.63 44.22-1.77 17.06A1.86 1.86 0 0 1 53 63H30.17a1.88 1.88 0 0 1-1.88-1.75l-1.76-17.03"
        className="cls-3"
      />
    </g>
    <path d="m37.89 57.1-.69-8.45M47.15 57.1l.69-8.45" className="cls-3" />
  </svg>
);
export default Ricetta2;
