export const styles = {
  container: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: `100%`,
    textAlign: "right",
    paddingRight: "3px",
    whiteSpace: "nowrap"
  }
};
