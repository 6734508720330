import { isIOS } from "react-device-detect";

export const styles = {
  sidebarContainer: {
    content: {
      backgroundColor: "white",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
      transitionDuration: "500ms",
      height: "100vh",
      zIndez: "9999"
    }
  },
  categoryContainer: {
    height: isIOS ? "70%" : "75%",
    marginTop: "20px"
  },
  resultContainer: {
    width: "100%",
    textAlign: "center",
    marginTop: "15px"
  },
  categoryName: (color) => {
    return {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color
    };
  },
  subCategoryName: (color) => {
    return {
      fontSize: "1.3rem",
      marginLeft: "20px",
      color
    };
  },
  boldClass: {
    fontWeight: "bold"
  },
  marginBottomClass: {
    marginBottom: "10px"
  },
  buttonScrollUpBtn: (display, bgColor, size = 45) => {
    return {
      padding: "7px",
      borderRadius: "100%",
      display: display,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: bgColor,
      width: `${size}px`,
      height: `${size}px`,
      fontSize: "23px",
      border: "1px solid grey",
      color: "black"
    };
  },
  filterScrollUp: (bgColor, marginB, size = 56) => {
    return {
      padding: "15px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "white",
      color: "var(--secondary-color)",
      bottom: `${marginB}px`,
      transitionDuration: "400ms",
      width: `${size}px`,
      height: `${size}px`,
      boxShadow: "0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28)"
    };
  },
  displayType: (typeDisplay = "block") => {
    return {
      display: typeDisplay
    };
  }
};
