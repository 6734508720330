import React, { useState } from "react";
import { getPDF, getCSV } from "../../services/grocery_list.services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import Loader from "../common/Loader";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";

const ExportButton = ({ type, idParamUrl }) => {
  const [loader, setLoader] = useState(false);
  const handleClick = () => {
    setLoader(true);
    let functionToUse = "";
    if (type !== "pdf" && type !== "csv") {
      console.log("Formato non supportato");
      setLoader(false);
      return;
    }

    if (type === "pdf") {
      functionToUse = getPDF;
    } else if (type === "csv") functionToUse = getCSV;

    functionToUse({ id_param_url: idParamUrl, show_message: false })
      .then((response) => downloadFile(response))
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const downloadFile = (response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Carrello.${type}`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <button onClick={handleClick} className="btn btn-secondary">
        <FontAwesomeIcon
          icon={type === "pdf" ? faFilePdf : faFileCsv}
          size="sm"
          color={"white"}
        />{" "}
        {language[defaultLanguage][`esporta_${type}`]}
      </button>
      {loader && (
        <Loader
          title={`Generazione ${type} in corso`}
          background={"rgba(0, 0, 0, 0.46)"}
        />
      )}
    </>
  );
};

export default ExportButton;
