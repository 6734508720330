import React from "react";
// import { Player } from "video-react";

const VideoModal = ({ data }) => {
  const player =
    data.video_type === "video_file" ? (
      <div className="col-12 m-auto video_file video-responsive">
        <video
          autoPlay
          playsInline
          height="100%"
          controls
          style={{ height: "calc(80vh - 115px)", width: "100%" }}
        >
          <source src={process.env.PUBLIC_URL + data.link} type="video/mp4" />
        </video>
      </div>
    ) : (
      <div
        className="video-responsive video_iframe col-12 m-auto"
        style={{ height: "100%", width: "100%" }}
      >
        <iframe
          title={data.link}
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${data.link}?autoplay=1&mute=1`}
        />
      </div>
    );

  return <>{player}</>;
};

export default VideoModal;
