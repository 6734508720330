import * as React from "react"

const Pagine = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <rect
      width={34.25}
      height={43.38}
      x={20.83}
      y={16.26}
      className="cls-1"
      rx={1}
    />
    <path
      d="M59.64 25.4h2.29a2.29 2.29 0 0 1 2.28 2.28v38.81a2.29 2.29 0 0 1-2.28 2.29H32.25A2.3 2.3 0 0 1 30 66.49v-2.28"
      className="cls-1"
    />
  </svg>
)
export default Pagine
