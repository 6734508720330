import * as React from "react"
const Categorie = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path
      d="M30.62 11.73h23.8a2 2 0 0 1 2 2V21h0-27.8 0v-7.27a2 2 0 0 1 2-2Z"
      className="cls-1"
    />
    <path d="M62.6 17.91h6.18v55.4H16.26v-55.4h6.18" className="cls-1" />
    <path
      d="m22.44 37.89 6.18 6.17 9.27-9.26M47.15 40.98h12.36M22.44 50.24l6.18 6.18 9.27-9.27M47.15 53.33h12.36"
      className="cls-1"
    />
  </svg>
)
export default Categorie
