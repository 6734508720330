import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import GroceryList from "../floatingGroceryList/GroceryList";
import { connect } from "react-redux";
import {
  setIndexCarousel,
  isSidebarRightOpen
} from "../../redux/carousel/action";
import { serverResponse } from "../../util/fakeServer";
import { defaultLanguage, getValue } from "../../helper/utility";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { iconAppNameNegative } from "helper/constant";
import { styles } from "./rightSidebar.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { language } from "../../languageProvider/language/language";
import { Carrello, ListaSpesa } from "../../assets/images/icone";
import ManagerFactory from "../../managers/ManagerFactory";

const manager = ManagerFactory.getManager();

class RightSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      sidebarOpen: false,
      globalHeigth: ""
    };
    this.logo_full = React.createRef();
    this.resize = this.resize.bind(this);
  }

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });

    // if (this.state.popoverOpen) {
    //   if (serverResponse.config.ga_active) {
    //     ReactGA.pageview(
    //       `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/lista-spesa`
    //     );
    //   }
    // }
  };

  getIndexPage = (p) => {
    if (serverResponse.config.show_one_page) {
      return p;
    } else {
      return parseInt(p / 2);
    }
  };

  sortLinks = () => {
    let links_internal = [];
    let links_external = [];
    serverResponse.leaflet.index.links.map((e) =>
      e.page !== -1 ? links_internal.push(e) : links_external.push(e)
    );

    const union = [
      ...links_external,
      ...links_internal.sort((a, b) => a.page - b.page)
    ];
    return union;
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    if (
      parseInt(new URLSearchParams(this.props.location.search).get("cart")) ===
        1 &&
      !isMobile
    ) {
      this.setState({ popoverOpen: true });
    }
  }

  resize() {
    this.setState({ globalHeigth: window.innerHeight - 220 });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  render() {
    if (serverResponse.config.marker_product_in_list) {
      var items = Array.from(this.props.groceryList);
      setTimeout(() => {
        items.forEach((item) => {
          var image = document.getElementById("selected-" + item.item_id);
          if (image != null) {
            image.style["display"] = "inline";
          }
        });
      }, 1000);
    }
    let imgsrc = this.props.themeMapping.clientIcon;
    let img_full =
      serverResponse.config.logo_full !== undefined &&
      serverResponse.config.logo_full !== null
        ? serverResponse.config.logo_full
        : iconAppNameNegative;
    let imgsrcDef = require("assets/images/logo_client.png");

    const toggleOpenSidebar = () => {
      if (this.props.sidebarRightOpen) {
        this.props.isSidebarRightOpen(false);
      } else {
        this.props.isSidebarRightOpen(true);
      }
    };

    // var limit = isSafari ? 10 : 18;
    var limit = 18;

    var getTitle = (text) => {
      let title = text;
      if (title.length > limit) {
        title = text.substring(0, limit - 3) + "...";
      }
      return title;
    };

    return (
      <div
        id="right-sidebar"
        ref={this.right_sidebar}
        className="right-sidebar d-none d-lg-block shadow"
        style={styles.sidebar(
          "white",
          this.props.sidebarRightOpen ? "260px" : manager.getBaseWidth()
        )}
      >
        <div className="right-sidebar-header">
          <NavLink to={"/"} className="simple-text logo-mini logo-mini-right">
            <div
              style={
                this.props.sidebarRightOpen ? null : styles.img_close_wrapper
              }
              className="logo-img"
            >
              {!this.props.sidebarRightOpen && (
                <img
                  className="right-sidebar-img-logo"
                  src={imgsrc}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = imgsrcDef;
                  }}
                  alt="react-logo"
                />
              )}
              {this.props.sidebarRightOpen && (
                <img
                  id="logo-full"
                  ref={this.logo_full}
                  style={styles.logo_full}
                  src={img_full}
                  alt="react-logo"
                />
              )}
            </div>
          </NavLink>
          {serverResponse.leaflet.index !== null &&
            serverResponse.leaflet.index.links.length > 0 &&
            serverResponse.config.show_right_index && (
              <>
                <div
                  className={
                    this.props.sidebarRightOpen ? "text-left" : "text-center"
                  }
                  onClick={toggleOpenSidebar}
                >
                  <button className="open-sidebar-icon c-btn my-3">
                    <i
                      className={`fas ${
                        this.props.sidebarRightOpen
                          ? "fa-arrow-right"
                          : "fa-arrow-left"
                      } `}
                    />
                  </button>
                </div>
                <div
                  id="links-scroll"
                  ref={this.links_scroll}
                  style={styles.link_scroll(
                    this.state.globalHeigth,
                    this.props.sidebarRightOpen ? "scroll" : "hidden"
                  )}
                >
                  {serverResponse.config.show_right_index &&
                    this.sortLinks().map((link, key) => {
                      return (
                        <div
                          onClick={() => {
                            if (link.type === "internal_link") {
                              this.props.setIndexCarousel(getValue(link.page));
                              this.props.history.push("/leaflet");
                            } else window.open(link.url);
                          }}
                          key={key}
                          className={`mt-1 link-container ${
                            this.props.sidebarRightOpen
                              ? "text-left"
                              : "text-center"
                          }`}
                          title={link.title}
                          style={styles.link_container(link.color)}
                        >
                          <button className="btn">
                            <p
                              style={{
                                color: link.color
                              }}
                            >
                              {link.type === "internal_link" ? (
                                link.page
                              ) : (
                                <FontAwesomeIcon
                                  icon={faExternalLinkAlt}
                                  className="change-product-quantity-icon"
                                  style={{
                                    color: link.color
                                  }}
                                />
                              )}
                              {this.props.sidebarRightOpen && (
                                <span style={styles.link_title}>
                                  {" "}
                                  - {getTitle(link.title)}
                                </span>
                              )}
                            </p>
                          </button>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
        </div>
        {!serverResponse.config.hide_grocery_list && (
          <div>
            <div className="right-sidebar-wrapper">
              <ul className="nav">
                <li style={styles.icon_list_wrapper}>
                  <NavLink
                    to={"list.routepath"}
                    className="nav-link main-list"
                    onClick={(e) => {
                      e.preventDefault();
                      this.toggle();
                    }}
                  >
                    <div
                      className="marker verticalListaSpesa"
                      id="desktop-grocery"
                      title={language[defaultLanguage].la_tua_lista_della_spesa}
                    >
                      {!serverResponse.config.send_grocery_list_to_market && (
                        // <img
                        //   src={
                        //     !serverResponse.config.show_right_index
                        //       ? NewListWhite
                        //       : NewList
                        //   }
                        //   style={{ cursor: "pointer", width: "40px" }}
                        //   alt="list"
                        // />
                        <ListaSpesa />
                      )}
                      {serverResponse.config.send_grocery_list_to_market && (
                        // <FontAwesomeIcon
                        //   icon={faShoppingCart}
                        //   style={{
                        //     fontSize: "25px",
                        //     color: serverResponse.config.primary_color
                        //   }}
                        // />
                        <Carrello />
                      )}
                      <NotificationBadge
                        count={this.props.groceryList.length}
                        effect={Effect.SCALE}
                        className="custom-grocery-notification-badge"
                      />
                    </div>
                  </NavLink>
                </li>
              </ul>
            </div>

            <GroceryList
              isOpen={this.state.popoverOpen}
              toggle={this.toggle}
              placement={"left"}
              target="desktop-grocery"
              isMobile={false}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList.list,
    sidebarRightOpen: state.carousel.sidebarRightOpen
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { setIndexCarousel, isSidebarRightOpen })
)(RightSidebar);
