import * as React from "react"

const Prodotti = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path d="M33.89 29.1h30.67" className="cls-1" />
    <circle cx={24.31} cy={29.1} r={3.83} className="cls-1" />
    <path d="M33.89 42.52h30.67" className="cls-1" />
    <circle cx={24.31} cy={42.52} r={3.83} className="cls-1" />
    <path d="M33.89 55.94h30.67" className="cls-1" />
    <circle cx={24.31} cy={55.94} r={3.83} className="cls-1" />
  </svg>
)
export default Prodotti
