import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { serverResponse } from "../../util/fakeServer";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFilter } from "@fortawesome/free-solid-svg-icons";
import { styles } from "./categories.styles";
import "../../assets/css/custom.css";
import { language } from "../../languageProvider/language/language";
import { defaultLanguage } from "../../helper/utility";
import * as services from "../../services/product.services";
import { BREAKPOINT } from "../../helper/constant";
import useCurrentWidth from "../../hooks/widthHook";
import Product from "../productsList/Product";
import OffCanvas from "react-aria-offcanvas/dist/OffCanvas";
import Loader from "../common/Loader";
import ManagerFactory from "../../managers/ManagerFactory";

const manager = ManagerFactory.getManager();

const CategoriesDynamic = (props) => {
  const [loading, setLoading] = useState(true);
  const [optionsCat, setOptionsCat] = useState([]);
  const [optionsSubCat, setOptionsSubCat] = useState([]);
  const [form, setForm] = useState({
    input_search: "",
    category: null,
    subcategory: null
  });
  const [sidebarFilterOpen, setSidebarFilterOpen] = useState(false);
  const [itemsToRender, setItemsToRender] = useState([]);

  const loadingProductsRef = useRef(false);

  const categoriesSelect = useRef(null);
  const subCategoriesSelect = useRef(null);

  const [showScrollUp, setShowScrollUp] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);

  const refScroll = useRef(null);
  const width = useCurrentWidth();

  useEffect(() => {
    const scrollTarget =
      width >= BREAKPOINT.lg
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    scrollTarget.addEventListener("scroll", trackScrolling);
    getCategories();
  }, []);

  useEffect(() => {
    if (currentPage > 1 && itemsToRender.length < totalProducts) handleScroll();
  }, [currentPage]);

  const formatCat = (obj) => {
    let opt = [];
    obj.arr.map((element) =>
      opt.push({
        value: obj.key ? element[obj.key] : element,
        label: obj.key ? element[obj.key] : element,
        id: element.id,
        sub: element.subcategories
      })
    );
    return opt;
  };

  const resetFilter = () => {
    categoriesSelect.current.clearValue();
    subCategoriesSelect.current.clearValue();
    setItemsToRender([]);
    setSidebarFilterOpen(false);
    setCurrentPage(1);
    setForm({
      input_search: "",
      category: null,
      subcategory: null
    });
  };

  const onChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  };

  const getCategories = () => {
    services
      .getCategories(form)
      .then((res) => {
        if (res.status === 200) {
          setOptionsCat(
            formatCat({ arr: res.data.data, key: "category_name" })
          );
        }
      })
      .catch((err) => {
        console.log("this is error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearch = () => {
    setItemsToRender([]);
    setSidebarFilterOpen(false);
    setCurrentPage(1);
    setLoadingProducts(true);
    let newObj = {
      ...form,
      page: 1
    };
    services
      .getProducts(newObj)
      .then((data) => {
        if (data.status === 200) {
          setItemsToRender((prev) => [...prev, ...data.data.data.items]);
          setTotalProducts(data.data.data.total);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingProducts(false));
  };

  const handleScroll = () => {
    if (loadingProducts) {
      return;
    }

    let newObj = {
      ...form,
      page: currentPage
    };
    loadingProductsRef.current = true;
    setLoadingProducts(true);
    services
      .getProducts(newObj)
      .then((data) => {
        if (data.status === 200) {
          setItemsToRender((prev) => [...prev, ...data.data.data.items]);
          loadingProductsRef.current = false;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingProducts(false);
        // setCallApi(true);
      });
  };

  const isBottom = (el) => {
    return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
  };

  const trackScrolling = () => {
    const scrollTarget =
      width >= BREAKPOINT.lg
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    if (isBottom(scrollTarget)) {
      const loadingProducts = loadingProductsRef.current;
      if (!loadingProducts) {
        setCurrentPage((prev) => prev + 1);
      }
    }
  };

  return (
    <>
      {!!(width < BREAKPOINT.lg) && (
        <OffCanvas
          width={"250"}
          className="shadow d-block d-lg-none"
          style={styles.sidebarContainer}
          isOpen={sidebarFilterOpen}
          onClose={() => {
            setSidebarFilterOpen(false);
          }}
          labelledby="menu-button"
        >
          <h4 style={styles.marginBottomClass}>
            {language[defaultLanguage].filtra_prodotti}
          </h4>
          {manager.isSearchProductVisible() && (
            <Input
              placeholder={language[defaultLanguage].prodotto}
              id="product_"
              name="input_search"
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSearch();
              }}
              onChange={(e) => {
                onChange({
                  name: "input_search",
                  value: e.target.value
                });
              }}
              value={form.input_search}
            />
          )}

          <div style={{ ...styles.categoryContainer, overflowY: "scroll" }}>
            {optionsCat.map((element, key) => {
              return (
                <div key={`${element.id}_${key}`}>
                  <p
                    className="pointer"
                    style={styles.categoryName(
                      form.category === element.id
                        ? serverResponse.config.secondary_color
                        : "black"
                    )}
                    onClick={() => {
                      onChange({ name: "category", value: element.id });
                    }}
                  >
                    {element.value} ({element.sub.length})
                  </p>

                  {element.id === form.category &&
                    element.sub.map((subs, key) => {
                      return (
                        <p
                          className="pointer"
                          style={styles.subCategoryName(
                            form.subcategory === subs.id
                              ? serverResponse.config.secondary_color
                              : "black"
                          )}
                          key={key}
                          onClick={() => {
                            onChange({ name: "subcategory", value: subs.id });
                          }}
                        >
                          {subs.category_name}
                        </p>
                      );
                    })}
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center">
            <button
              style={{ borderRadius: "30px", border: "1px solid" }}
              disabled={
                !form.category &&
                !form.subcategory &&
                form.input_search.replace(/\s/g, "") === ""
              }
              className="btn btn-primary d-sm-block "
              onClick={() => handleSearch()}
            >
              {language[defaultLanguage].cerca}
            </button>
            <button
              style={{ borderRadius: "30px", border: "1px solid" }}
              disabled={loadingProducts || loading}
              className="btn btn-outline d-sm-block "
              onClick={() => resetFilter()}
            >
              {language[defaultLanguage].reset}
            </button>
          </div>
        </OffCanvas>
      )}

      <div
        ref={refScroll}
        style={{overflowX: "hidden"}}
        className="container-fluid styleScrollableCategories"
        id="scrollable-div"
        onScroll={(e) => {
          if (e.target.scrollTop > 300) {
            if (!showScrollUp) {
              setShowScrollUp(true);
            }
          } else {
            if (showScrollUp) {
              setShowScrollUp(false);
            }
          }
        }}
      >
        {/* SEARCH BAR */}
          <div className="row m-0 py-1">
            <div className="col-12 col-md-10 p-0">
              <div className="row">
                <div className="col-12 col-md-4">
                  <Select
                    ref={categoriesSelect}
                    // value={form.category}
                    isSearchable={true}
                    name="category"
                    options={optionsCat}
                    placeholder={serverResponse.config.label_category}
                    onChange={(e) => {
                      if (e) {
                        setOptionsSubCat(
                          formatCat({ arr: e.sub, key: "category_name" })
                        );
                        onChange({ name: "category", value: e.id });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-md-4 my-3 my-md-0">
                  <Select
                    ref={subCategoriesSelect}
                    // value={form.subcategory}
                    isDisabled={!form.category}
                    isSearchable={true}
                    name="subcategory"
                    options={optionsSubCat}
                    placeholder={serverResponse.config.label_subcategory}
                    onChange={(e) => {
                      if (e) {
                        onChange({ name: "subcategory", value: e.id });
                      }
                    }}
                  />
                </div>
                {manager.isSearchProductVisible() && (
                  <div className="col-12 col-md-4">
                    <InputGroup className="h-100">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text icon-input-group">
                          <FontAwesomeIcon icon={faBars} />
                        </span>
                      </InputGroupAddon>
                      <Input
                        placeholder={language[defaultLanguage].prodotto}
                        id="product"
                        name="input_search"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") handleSearch();
                        }}
                        onChange={(e) => {
                          onChange({
                            name: "input_search",
                            value: e.target.value
                          });
                        }}
                        value={form.input_search}
                      />
                    </InputGroup>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-2 d-flex mt-3 my-md-0">
              <button
                style={{ borderRadius: "30px", border: "1px solid" }}
                disabled={loadingProducts || loading}
                className="btn btn-outline  w-100  btn_dynamic_cat"
                onClick={() => resetFilter()}
              >
                {language[defaultLanguage].reset}
              </button>
              <button
                style={{ borderRadius: "30px" }}
                disabled={
                  !form.category &&
                  !form.subcategory &&
                  form.input_search.replace(/\s/g, "") === ""
                }
                className="btn btn-primary w-100 btn_dynamic_cat"
                onClick={() => handleSearch()}
              >
                {language[defaultLanguage].cerca}
              </button>
            </div>
          </div>
        {/* END SEARCH BAR */}

        <div
          id="scroller"
          className="row ma-0 pt-3 filtered-products justify-content-start"
        >
          {!loadingProducts && !loading && itemsToRender.length === 0 && (
            <img
              style={{
                width: "50%",
                textAlign: "center",
                margin: "auto",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }}
              className="img-fluid"
              src={serverResponse.config.logo_full}
              alt="logo"
            ></img>
          )}
          {loading && itemsToRender.length <= totalProducts ? (
            <Loader background={"transparent"} />
          ) : (
            itemsToRender.map((el, key) => {
              return (
                <div
                  key={`${el.id}_${key}`}
                  className="col-sm-12 col-lg-6 col-xl-3 product_card_container pb-3"
                >
                  <Product
                    fromProducts={true}
                    elementIndex={key}
                    dynamic_price={el.dynamic_price}
                    dynamic_info={el.dynamic_info}
                    info={el}
                  />
                </div>
              );
            })
          )}
          {loadingProducts && itemsToRender.length <= totalProducts && (
            <div
              className="spinner-grow text-center"
              style={{
                width: "100px",
                height: "100px",
                color: serverResponse.config.secondary_color
              }}
              role="status"
            ></div>
          )}
        </div>
        {false && !loading && (
          <div
            onClick={() => {
              setSidebarFilterOpen(true);
            }}
            className="filter-scroll-up d-flex d-lg-none d-flex justify-content-center flex-column"
            style={styles.filterScrollUp(
              serverResponse.config.secondary_color,
              showScrollUp ? "10" : "10",
              56
            )}
          >
            <FontAwesomeIcon
              onClick={() => {
                setSidebarFilterOpen(true);
              }}
              size="1x"
              style={styles.displayType()}
              icon={faFilter}
            />
            <p style={{ fontSize: "0.8rem" }}>
              {language[defaultLanguage].filtra}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoriesDynamic;
