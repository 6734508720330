import * as React from "react";
const CondividiNew = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <circle cx={59.64} cy={25.4} r={9.13} className="cls-2" />
    <circle cx={25.4} cy={41.38} r={9.13} className="cls-2" />
    <circle cx={59.64} cy={59.64} r={9.13} className="cls-2" />
    <path
      d="m50.51 55.08-15.98-9.14M50.51 27.68l-15.98 9.13"
      className="cls-2"
    />
  </svg>
);
export default CondividiNew;
