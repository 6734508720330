export const styles = {
  shareBtn: (bg_color) => {
    return {
      // backgroundColor: bg_color,
      borderColor: bg_color,

      background: "var(--primary-color)",
      borderRadius: "30px",
      color: "white",
      padding: "5px",
      textDecoration: "none !important"
    };
  }
};
