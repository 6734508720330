import * as React from "react"
const Carrello = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path
      d="M25.88 29.72H72L62.34 56.2a2.56 2.56 0 0 1-2.41 1.68H30.7a2.56 2.56 0 0 1-2.54-2.24l-4.84-38.72H13.08"
      className="cls-1"
    />
    <circle cx={59.16} cy={63} r={5.12} className="cls-1" />
    <circle cx={31} cy={63} r={5.12} className="cls-1" />
    <path d="M36.12 39.12h23.04M36.12 47.9h19.71" className="cls-1" />
  </svg>
)
export default Carrello
