import React from "react";
import AdComponent from "../components/leaflet/Adsense";
import CookieConsentBanner from "../components/leaflet/CookieConsentBanner";
import { serverResponse } from "../util/fakeServer";
import PrivacyPolicy from "../components/common/PrivacyPolicy";
// import CategoriesStatic from "../views/CategoriesStatic";
// import CategoriesStatic from "../components/Categories/CategoriesStatic";

class DefaultManager {
  signboardId;

  constructor(signboardId) {
    this.signboardId = signboardId;
  }

  getPlusIcon() {
    return null;
  }

  getPlayIcon() {
    return null;
  }

  noAddCart() {
    return false;
  }

  getTitleType() {
    if (global.footer_session) return global.footer_session;

    return {};
  }

  getMarkerIconClass() {
    return "";
  }

  getConsentBanner() {
    return <CookieConsentBanner />;
  }

  getVideoProps() {
    return {
      muted: true,
      controls: false
    };
  }

  getComponentCategory() {
    return "CategoriesStatic";
  }

  getHAdsense() {
    return 90;
  }

  getBannerComponent() {
    return <AdComponent />;
  }

  filterMarkers() {
    return null;
  }

  getStoreButton(link) {
    return null;
  }

  getRowClass(type) {
    return "";
  }

  getAnalyticsId() {
    return serverResponse.config.ga_tracking_id;
  }

  gaAnalyticsEvent(event, item) {
    return false;
  }

  getVisibleInteractivities() {
    return [
      "attachment",
      "plus",
      "carousel",
      "world",
      "play",
      "info",
      "hat-chef",
      "specs",
      "promo",
      "external_link",
      "internal_link"
    ];
  }

  applyFilterOnMarker() {
    return false;
  }

  isSearchProductVisible() {
    return true;
  }

  getCategoriesPlaceholder() {
    return {
      category: "Scegli una categoria",
      subcategory: "Scegli una sottocategoria"
    };
  }

  getDefaultFont() {
    return "M PLUS Rounded 1c, sans-serif";
  }

  getIvaLabel() {
    return null;
  }

  linkToShop() {
    return false;
  }

  getShopUrl(markers) {
    return null;
  }

  getProdottiLabel(label) {
    return label;
  }

  getPrivacyText() {
    return <PrivacyPolicy />;
  }

  forceVisit() {
    return true;
  }

  getBaseWidth() {
    return "3.5rem";
  }
  showList() {
    return true;
  }
}

export default DefaultManager;
