import React, { Component } from "react";
import ManagerFactory from "../managers/ManagerFactory";
import CategoriesIndex from "../components/Categories/CategoriesIndex";

const manager = ManagerFactory.getManager();
class Categories extends Component {
  render() {
    const ComponentToRender = CategoriesIndex[manager.getComponentCategory()];

    return <ComponentToRender {...this.props} />;
  }
}

export default Categories;
