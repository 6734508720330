import React from "react";
import { Route, Switch } from "react-router-dom";
import layoutRoutes from "../routes/index";
import Login from "../views/Login.pages";
import PrivateRoute from "./PrivateRoute.route";
import { connect } from "react-redux";
import SetPasswordPages from "../views/SetPassword.pages";
import { serverResponse } from "../util/fakeServer";

const Routes = (props) => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      {!serverResponse.config.passwordProtected && (
        <Route path="/register" component={Login} />
      )}
      {!serverResponse.config.passwordProtected && (
        <Route path="/reset-psw" component={Login} />
      )}
      {!serverResponse.config.passwordProtected && (
        <Route path="/set-psw" component={SetPasswordPages} />
      )}

      {layoutRoutes.map((prop, key) => (
        <PrivateRoute
          path={prop.path}
          component={prop.component}
          themeMapping={props.theme}
          // render={(routeProps) => (
          //   <prop.component {...routeProps} themeMapping={props.theme} />
          // )}
          key={key}
        />
      ))}
    </Switch>
  );
};
const mapStateToProps = (state) => ({
  authJWT: state.authJWT
});
export default connect(mapStateToProps)(Routes);
