import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { logoutJWT, toggleUserModal } from "../../redux/authJWT/action";

import { defaultLanguage } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
import { serverResponse } from "../../util/fakeServer";

const UserInfoModal = (props) => {
  const history = useHistory();
  const handleOnPress = () => {
    const confirmLogout = window.confirm("Vuoi effettuare il logout?");
    if (confirmLogout) {
      props.logoutJWT(history);
    }
  };
  const {
    authJWT: {
      user: { name, surname, email }
    }
  } = props;
  return (
    <Modal
      toggle={() => {
        props.toggleUserModal();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
      }}
      centered
      isOpen={props.authJWT.modalUserOpen}
    >
      <ModalHeader>
        {language[defaultLanguage].benvenuto} {name}
      </ModalHeader>
      <ModalBody>
        {!serverResponse.config.passwordProtected && (
          <div>
            <p>
              {language[defaultLanguage].email}:<strong> {email}</strong>
            </p>
            <p>
              {language[defaultLanguage].nome}:<strong> {name}</strong>
            </p>
            <p>
              {language[defaultLanguage].cognome}:<strong> {surname}</strong>
            </p>
          </div>
        )}

        <div className="d-flex justify-content-end">
          <Button onClick={handleOnPress} className="mt-3 text-rigth">
            Logout
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, {
  logoutJWT,
  toggleUserModal
})(UserInfoModal);
