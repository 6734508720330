import React from "react";

import { serverResponse } from "../../util/fakeServer";
import { connect } from "react-redux";
import {
  setIndexCarousel,
  setVerticalPages
} from "../../redux/carousel/action";
import { BREAKPOINT, H_COMPONENTS } from "../../helper/constant";
import { isMobileSafari } from "react-device-detect";
import { isSupported, scrollToSlide } from "../../helper/utility";
import useCurrentWidth from "../../hooks/widthHook";

var isMobile = window.innerWidth < BREAKPOINT.lg;

const CustomIndex = (props) => {
  const width = useCurrentWidth();
  const listLink = props.index.links.map((element, key) => {
    if (element.type === "url") {
      return (
        <CustomUrl
          elId={element.id}
          key={key}
          page={element.page}
          blueprint={element.blueprint}
          url={element.url}
          target={element.target}
        />
      );
    } else {
      return (
        <CustomLink
          elId={element.id}
          key={key}
          page={element.page}
          blueprint={element.blueprint}
          setIndexCarousel={props.setIndexCarousel}
          goToPage={props.goToPage}
          setVerticalPages={props.setVerticalPages}
          verticalPages={props.carouselIndex.verticalPages}
        />
      );
    }
  });

  const safariDetectMobile = isMobileSafari ? 30 : 0;

  let uriImage = `${process.env.PUBLIC_URL}${
    isSupported
      ? props.index.webp_image_file || props.index.image_file
      : props.index.image_file
  }?v=${serverResponse.config.version}`;

  return (
    <div
      id="slide_0"
      className="d-flex p-0 flex-column justify-content-center align-items-center bordo"
    >
      <div style={{ position: "relative" }}>
        <div className={`shadow-${props.shadow}`}></div>
        <img
          alt=""
          src={uriImage}
          className="img-fluid"
          style={{
            maxHeight:
              width < BREAKPOINT.lg && serverResponse.config.scrollVertical
                ? "unset"
                : serverResponse.config.adsense
                ? `calc(100vh - ${H_COMPONENTS.header +
                    H_COMPONENTS.adsense +
                    H_COMPONENTS.pager +
                    safariDetectMobile}px)`
                : `calc(100vh - ${H_COMPONENTS.header +
                    H_COMPONENTS.pager +
                    safariDetectMobile}px)`
          }}
        />
        {listLink}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps, { setIndexCarousel, setVerticalPages })(
  CustomIndex
);

const getIndex = (index) => {
  let p = 0;
  if (isMobile || serverResponse.config.show_one_page) {
    if (serverResponse.leaflet.index === null) {
      p = index - 1;
    } else {
      p = index;
    }
  } else {
    if (serverResponse.leaflet.index === null) {
      p = parseInt(index / 2, 10);
    } else {
      p = parseInt(index / 2, 10);
    }
  }
  return p;
};

const CustomLink = (props) => {
  const width = useCurrentWidth();
  const styleProduct = {
    position: "absolute",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    top: props.blueprint.top + "%",
    left: props.blueprint.left + "%",
    width: props.blueprint.width + "%",
    height: props.blueprint.height + "%"
  };

  let page =
    !isMobile && props.page % 2 !== 0 && !serverResponse.config.show_one_page
      ? props.page - 1
      : props.page;
  return (
    <div
      type="fadeAnimationInt"
      id={`div-${props.elId}`}
      className="customLink"
      style={styleProduct}
      onClick={() => {
        props.setIndexCarousel(getIndex(page));
        if (
          (serverResponse.config.scrollVertical ||
            serverResponse.config.scrollHorizontal) &&
          width < BREAKPOINT.lg
        ) {
          scrollToSlide(page, props.setVerticalPages, props.verticalPages);
        }
      }}
    ></div>
  );
};

const CustomUrl = (props) => {
  const styleProduct = {
    position: "absolute",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    top: props.blueprint.top + "%",
    left: props.blueprint.left + "%",
    width: props.blueprint.width + "%",
    height: props.blueprint.height + "%"
  };

  return (
    <div
      type="fadeAnimationInt"
      id={`div-${props.elId}`}
      className="customLink"
      style={styleProduct}
      onClick={() => {
        if (props.target === undefined) window.open(props.url, "_self");
        else window.open(props.url, "_blank");
      }}
    ></div>
  );
};
