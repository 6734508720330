import * as React from "react"
const ArrowsLx = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_2"
    data-name="Livello 2"
    viewBox="0 0 30.71 36.85"
    {...props}
  >
    <g id="Livello_1-2" data-name="Livello 1">
      <path d="M19.2.77.77 18.43 19.2 36.09" className="cls-1" />
      <path d="M29.95.77 11.52 18.43l18.43 17.66" className="cls-1" />
    </g>
  </svg>
)
export default ArrowsLx
