import React from "react";
import { iconDemo } from "../../helper/constant";
import { serverResponse } from "../../util/fakeServer";

const errorState = {
  sessionStorage: {
    error: "Sembra che non siano stati forniti i permessi per i cookie",
    solution: "Vai nelle impostazioni del tuo browser e abilita i cookie "
  },
  localStorage: {
    error: "Sembra che non siano stati forniti i permessi per i cookie",
    solution: "Vai nelle impostazioni del tuo browser e abilita i cookie "
  },
  default: {
    error: "Sembra si sia verificato un errore imprevisto",
    solution: "Stiamo lavorando ad una soluzione,torneremo online presto."
  }
};

const style = {
  test: { background: "#fafafa", height: "100vh" }
};

const ErrorComponent = ({ err, sol }) => {
  return (
    <div className="container mt-3" style={style.test}>
      <div className="row alert alert-danger shadow">
        <div className="col-2">
          <img className="img-fluid" src={iconDemo} alt="logo" />
        </div>
        <div className="col-10">
          <h1>Ops qualcosa è andato storto</h1>
          <h3>Errore : {err}</h3>
        </div>
      </div>

      <div className="alert alert-warning shadow" role="alert">
        <h3>Soluzione: {sol}</h3>
      </div>
    </div>
  );
};

export class AppError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorType: "", solution: "" };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  logErrorToServer = (errorType) => {
    const url = "https://viewmanager.interattivo.net/api/logger/write";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        level: 0,
        message: errorType,
        source: "view.interattivo.net",
        requestParams: {
          clientId: serverResponse.config.client_id,
          signboardId: serverResponse.config.signboard_id,
          leafletId: serverResponse.leaflet.id
        }
      })
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);

    if (error) {
      for (const key in errorState) {
        if (Object.values(error)[0]) {
          if (Object.values(error)[0].includes(key)) {
            this.setState({
              errorType: errorState[key].error,
              solution: errorState[key].solution
            });
            this.logErrorToServer(Object.values(error)[0]);
            break;
          } else {
            this.setState({
              errorType: errorState.default.error,
              solution: errorState.default.solution
            });
          }
        } else {
          this.setState({
            errorType: errorState.default.error,
            solution: errorState.default.solution
          });
        }
      }
    }

    this.setState({ error, errorInfo });
  }

  render() {
    const { hasError, errorType, solution } = this.state;

    const { children } = this.props;

    return hasError ? (
      <ErrorComponent err={errorType} sol={solution} />
    ) : (
      children
    );
  }
}
