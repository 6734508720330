import React from "react";
import PlusIperbimbo from "../assets/images/icone/PlusIperbimbo.icona";
import VideoIperbimbo from "../assets/images/icone/VideoIperbibmo.icona";
import CookieConsentBannerIP from "../components/leaflet/CookieConsentBannerIP";
import DefaultManager from "./Default.manager";

class IperbimboManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getPlusIcon() {
    return <PlusIperbimbo />;
  }

  getPlayIcon() {
    return <VideoIperbimbo />;
  }

  getMarkerIconClass() {
    return "iperBimboMarker";
  }
  getConsentBanner() {
    return <CookieConsentBannerIP />;
  }

  getVideoProps() {
    return {
      muted: false,
      controls: true
    };
  }
}

export default IperbimboManager;
