import * as React from "react"
const ArrowsRx = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_2"
    data-name="Livello 2"
    viewBox="0 0 30.71 36.85"
    {...props}
  >
    <g id="Livello_1-2" data-name="Livello 1">
      <path d="m11.52 36.09 18.43-17.66L11.52.77" className="cls-1" />
      <path d="M.77 36.09 19.2 18.43.77.77" className="cls-1" />
    </g>
  </svg>
)
export default ArrowsRx
