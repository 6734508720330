import React from "react";
import StoreModal from "../components/modal/modalbody/components/Store.modal";
import DefaultManager from "./Default.manager";

class InfoManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  filterMarkers() {
    return "world";
  }

  getStoreButton(link) {
    switch (this.signboardId) {
      case 1438:
      case 1497:
        return <StoreModal link={link} />;

      default:
        return null;
    }
  }

  getRowClass(type) {
    return type === "play"
      ? "flex-column align-items-center video-button-store"
      : "";
  }

  linkToShop() {
    return +this.signboardId === 1550;
  }

  getShopUrl(markers) {
    for (let i = 0; i < markers.length; i++) {
      if (markers[i].type === "world") return markers[i].data;
    }
    return "https://volantinointerattivo.net";
  }
}

export default InfoManager;
