import React from "react";
import { connect } from "react-redux";
import { toggleUserModal } from "../../redux/authJWT/action";

const UserAvatar = (props) => {
  const {
    authJWT: {
      user: { name, surname }
    }
  } = props;
  let initials = "VI";
  if (name && surname) initials = name[0] + surname[0];
  return (
    <div
      onClick={() => {
        props.toggleUserModal();
      }}
      className="user-avatar"
    >
      <span className="initials">{initials}</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authJWT: state.authJWT
  };
};

export default connect(mapStateToProps, { toggleUserModal })(UserAvatar);
