import React from "react";

const RudPrivacyPolicy = () => {
  return (
    <div
      style={{ maxHeight: "300px", overflowY: "scroll", overflowX: "hidden" }}
    >
      <p>
        <b>Interaktive Preisliste für Reifenketten</b>
      </p>
      <br />
      <p>
        Für die interaktive Darstellung des umfangreichen Produktkatalogs von
        Reifenketten nutzt der Verantwortliche "Volantino Interattivo", einen
        Service der Badil s.r.l. unipersonale, Camera di commercio Salerno REA
        SA-469701, 84091 Battipaglia (SA), Italien (nachfolgend bezeichnet als
        „Badil“).
      </p>
      <br />
      <p>
        Die Verarbeitung der personenbezogenen Daten geschieht aufgrund des
        berechtigten Interesses des Verantwortlichen nach Art. 6 Abs. 1 lit. f
        DSGVO an der Gestaltung einer zeitgemäßen und interessengerechten
        Produkt- und Preisübersicht für Kunden und Interessenten.
      </p>
      <br />
      <p>
        Der Katalog wird bei Badil gehostet und technisch betreut. Um den
        Katalog aufrufen zu können, ist eine vorherige Registrierung
        erforderlich. Hierzu müssen Nutzer mindestens ihre E-Mail-Adresse, ein
        Passwort, ihren Namen und ihre Kundenummer eingeben. Anschließend
        erhalten Nutzer eine Bestätigungs-E-Mail, die einen Link zur
        Passwortvalidierung enthält. Über den Link gelangen Nutzer dann zum
        Katalog. Darüber hinaus können sich Nutzer unter{" "}
        <a href="https://view.interattivo.net/rud-de/pricelist">
          https://view.interattivo.net/rud-de/pricelist
        </a>{" "}
        jederzeit anmelden und den Katalog einsehen.
      </p>
      <br />
      <p>
        Innerhalb des Katalogs besteht über einen Facebook-Teilen-Button und
        einen WhatsApp-Button die Möglichkeit, ein bestimmtes Produkt über
        Facebook zu teilen oder einen Produktlink via WhatsApp zu versenden. Für
        beides ist die Registrierung der Nutzer beim jeweiligen Dienst
        erforderlich. Auf die Datenverarbeitung bei Facebook und/oder WhatsApp
        hat der Verantwortliche keinen Einfluss. Wenn Nutzer bei Facebook
        und/oder WhatsApp angemeldet sind, besteht die Möglichkeit, dass
        Nutzeraktionen im Katalog vom jeweiligen Anbieter mit der Nutzerkonto
        des jeweiligen Dienstes in Verbindung gebracht werden. Weitergehende
        Informationen zum Datenschutz sind abrufbar unter{" "}
        <a href="https://www.facebook.com/help/238318146535333/?helpref=uf_share">
          https://www.facebook.com/help/238318146535333/?helpref=uf_share{" "}
        </a>{" "}
        und unter{" "}
        <a href="https://faq.whatsapp.com/3307102709559968/?helpref=uf_share">
          https://faq.whatsapp.com/3307102709559968/?helpref=uf_share
        </a>
        . Nutzer haben die Möglichkeit, innerhalb des Katalogs individuelle
        Einstellungen vorzunehmen und Lesezeichen anzulegen. Sowohl individuelle
        Einstellungen als auch Lesezeichen werden im Local Storage des vom
        Nutzer verwendeten Endgeräts gespeichert.
      </p>
      <br />
      <p>
        Weitergehende Informationen zum Datenschutz bei Volantino Interattivo
        sind abrufbar unter{" "}
        <a href="https://www.iubenda.com/privacy-policy/46604161">
          https://www.iubenda.com/privacy-policy/46604161
        </a>
        .
      </p>
    </div>
  );
};

export default RudPrivacyPolicy;
