import React from "react";
import { connect } from "react-redux";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";
import TitleModal from "./components/Title.modal";

const SpecsBody = ({ modalRedux }) => {
  const {
    otherProps: {
      markerInfo: { title, data }
    }
  } = modalRedux;

  return (
    <>
      <div className="col-12 col-lg-6 h-lg-100" style={{ position: "relative" }}>
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6 py-3">
        <div className="mb-3">
          <TitleModal title={title} />
        </div>
        <div className="py-3">
          <ListModal list={data.specifiche} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal
  };
};
export default connect(mapStateToProps)(SpecsBody);
