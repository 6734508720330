import { defaultLanguage } from "../../helper/utility"
import { language } from "../../languageProvider/language/language"
import { serverResponse } from "../fakeServer"

export const strings = {
    "saveBtnTooltip": serverResponse.config.send_grocery_list_to_market ? language[defaultLanguage].aggiungi_al_carrello : language[defaultLanguage].aggiungi_alla_lista,
    "deleteAllBtnTooltip": serverResponse.config.send_grocery_list_to_market ? language[defaultLanguage].svuota_carrello : language[defaultLanguage].cancella_lista,
    "deleteBtnTooltip": serverResponse.config.send_grocery_list_to_market ? language[defaultLanguage].elimina_dal_carrello : language[defaultLanguage].elimina_dalla_lista,
    // "relatedProductsTitle": "Guarda anche:",
    "relatedProductsTitle": "ALTRE VARIANTI",
}