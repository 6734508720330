import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider } from "react-redux";
import { store } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";

// import Leaflet from "views/Leaflet";

//Fake Server
import { serverResponse } from "./util/fakeServer";

import "./assets/scss/app.scss";
import "pretty-checkbox/src/pretty-checkbox.scss";

import cssVars from "css-vars-ponyfill";
import { isIE } from "react-device-detect";
import { lightenDarkenColor } from "./helper/utility";
import ManagerFactory from "./managers/ManagerFactory";
import Routes from "./routing/Route";
import { getProfileJWT } from "./redux/authJWT/action";

const currentAppLocale =
  AppLocale[getCurrentLanguage(config.defaultLanguage || "italian").locale];

var basePath = process.env.PUBLIC_URL;

if (window.location.pathname.search("preview") !== -1) {
  basePath = "preview";
} else if (
  serverResponse.config.client_code !== null &&
  serverResponse.config.client_code !== ""
) {
  basePath = "";
}

const manager = ManagerFactory.getManager();

class App extends Component {
  componentDidMount = () => {
    // La pagina è all'interno di un iframe

    if (serverResponse.config.allow_login) store.dispatch(getProfileJWT());
  };

  render() {
    let theme = serverResponse.config;
    let root = document.documentElement;
    let vh = window.innerHeight * 0.01;

    let color_hover_sidebar = lightenDarkenColor(
      serverResponse.config.primary_color,
      -20
    );

    let font_client = manager.getDefaultFont();

    if (isIE) {
      cssVars({
        rootElement: root,
        variables: {
          "--primary-color": theme["primary_color"],
          "--secondary-color": theme["secondary_color"],
          "--tertiary-color": theme["tertiary_color"],
          "--hover-color": theme["hover_color"],
          "--background-color": theme["background_color"],
          "--primary-alpha-color": theme["primary-alpha-color"],
          "--hover-sidebar-color": color_hover_sidebar,
          "--h-adsense": `90px`,
          "--font-client": font_client
        }
      });
    } else {
      root.style.setProperty("--primary-color", theme["primary_color"]);
      root.style.setProperty("--secondary-color", theme["secondary_color"]);
      root.style.setProperty("--tertiary-color", theme["tertiary_color"]);
      root.style.setProperty("--background-color", theme["background_color"]);
      root.style.setProperty("--hover-color", theme["hover_color"]);
      root.style.setProperty(
        "--primary-alpha-color",
        theme["primary-alpha-color"]
      );
      root.style.setProperty("--hover-sidebar-color", color_hover_sidebar);
      root.style.setProperty("--vh", `${vh}px`);
      root.style.setProperty("--h-adsense", `90px`);
      root.style.setProperty("--font-client", font_client);
    }

    // eslint-disable-next-line no-unused-vars
    var default_redirect_path = "/categories";
    if (serverResponse.config.has_pages) {
      default_redirect_path = "/leaflet";
    }

    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <StyleRoot>
            <Provider store={store}>
              <Router basename={basePath}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to={default_redirect_path} />}
                  />
                  <Route>
                    <Routes theme={theme} />
                  </Route>
                </Switch>
                {/* <Routes theme={theme} /> */}
              </Router>
              {/* {manager.getConsentBanner()} */}

              {/*End layout routes */}
            </Provider>
          </StyleRoot>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
