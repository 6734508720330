import * as React from "react"
const PlusIperbimbo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 114.43 114.43"
    {...props}
  >
    <title>{"Risorsa 12"}</title>
    <g data-name="Livello 2">
      <g data-name="Livello 1">
        <circle
          cx={57.22}
          cy={57.22}
          r={55.72}
          style={{
            fill: "#fff",
            stroke: "#666",
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <path
          d="M86.66 61.42a4.27 4.27 0 0 1-4.21 4.21H65.63v16.82a4.27 4.27 0 0 1-4.21 4.21H53a4.27 4.27 0 0 1-4.21-4.21V65.63H32a4.27 4.27 0 0 1-4.2-4.21V53a4.25 4.25 0 0 1 4.2-4.2h16.81V32a4.25 4.25 0 0 1 4.2-4.2h8.41a4.27 4.27 0 0 1 4.21 4.2v16.81h16.82A4.25 4.25 0 0 1 86.66 53Z"
          style={{
            fill: "#666",
          }}
        />
      </g>
    </g>
  </svg>
)
export default PlusIperbimbo
