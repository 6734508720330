import * as React from "react";
const Negozio = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <path
      d="M68.78 34.17a5.28 5.28 0 0 1-10.51 0 5.27 5.27 0 0 1-10.5 0 5 5 0 0 1-5.25 4.77 5 5 0 0 1-5.25-4.77A5 5 0 0 1 32 38.94a5 5 0 0 1-5.25-4.77 5 5 0 0 1-5.26 4.77 5 5 0 0 1-5.25-4.77M16.26 34.17l5.48-17.37A2.38 2.38 0 0 1 24 15.07h37a2.39 2.39 0 0 1 2.3 1.73l5.48 17.37"
      className="cls-2"
    />
    <path
      d="M65.2 38.94v28.64A2.39 2.39 0 0 1 62.81 70H22.23a2.39 2.39 0 0 1-2.39-2.39V38.94"
      className="cls-2"
    />
    <rect
      width={9.55}
      height={11.94}
      x={48.49}
      y={46.1}
      className="cls-2"
      rx={1}
    />
    <path
      d="M28 46.1h9.94a1 1 0 0 1 1 1V70h0H27h0V47.1a1 1 0 0 1 1-1Z"
      className="cls-2"
    />
  </svg>
);
export default Negozio;
