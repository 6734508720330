import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const Allegato = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff"
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <path
      d="m24.87 41.93 17.57-17.57a11.47 11.47 0 1 1 16.21 16.22L37.71 61.52a8.6 8.6 0 0 1-12.16-12.16l12.09-12.09a5.73 5.73 0 0 1 8.11 8.1L35.68 55.44"
      style={{
        fill: "none",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 2
      }}
      className="cls-3"
    />
  </svg>
);
export default Allegato;
