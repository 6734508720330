import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon
} from "react-share";
import { isFirefox } from "react-device-detect";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { styles } from "./ShareGroceryListBtn.style";
import { NotificationManager } from "react-notifications";
import { defaultLanguage, getCustomActions } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
import { CondividiNew } from "../../assets/images/icone";

class ShareGroceryList extends Component {
  state = {
    modal: false,
    idParam: 0,
    copyAlert: false
  };

  openReactNativeShare = () => {
    let link =
      serverResponse.config.serverPath + "myGroceryList/" + this.state.idParam;
    let title = language[defaultLanguage].visualizza_lista_spesa;
    var str = title + "    " + link;
    var obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  openModal = () => {
    this.setState({ modal: true });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  pause = (timeoutMsec) =>
    new Promise((resolve) => setTimeout(resolve, timeoutMsec));

  exportGroceryList = (type = "") => {
    var tmpProducts = this.props.groceryList.list;

    tmpProducts.forEach((element) => {
      element.image_file = element.images[0]
        ? element.images[0].image_file
        : "";
    });

    var idParam = Math.random()
      .toString(36)
      .substr(2, 16);
    this.setState({
      idParam: idParam
    });

    var exportObj = {
      id_param_url: this.state.idParam,
      id_campagna: this.props.groceryList.idCampaign,
      prodotti: this.props.groceryList.list
    };

    getCustomActions().exportList(exportObj);

    if (type === "email") {
      let target = "_self";
      if (isFirefox) target = "_blank";
      window.open(
        `mailto:?body=${getCustomActions().getShareListLink(
          this.state.idParam
        )}`,
        target
      );
    }
  };

  componentDidMount() {
    var idParam = Math.random()
      .toString(36)
      .substr(2, 16);

    this.setState({
      idParam: idParam
    });
  }

  render() {
    let link = getCustomActions().getShareListLink(this.state.idParam);
    let title = language[defaultLanguage].visualizza_lista_spesa;

    let wa1 = "inline";
    let wa2 = "none";

    const copyToClip = () => {
      navigator.clipboard
        .writeText(link)
        .then(() =>
          NotificationManager.success(
            language[defaultLanguage].elemento_copiato,
            language[defaultLanguage].link_copiato_negli_appunti,
            1000
          )
        )
        .catch((err) => {
          NotificationManager.error(
            language[defaultLanguage].errore_nella_copia,
            language[defaultLanguage].link_non_copiato,
            1000
          );
        });
    };

    if (window.ReactNativeWebView !== undefined) {
      wa1 = "none";
      wa2 = "inline";
    }

    const shareButtonStyle =
      this.props.groceryList.list.length === 0
        ? {
            color: "white",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            background: "grey",
            width: "170px"
          }
        : {
            color: "white",
            // padding: "10px",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
            width: "170px"
          };

    let share_label = serverResponse.config.send_grocery_list_to_market
      ? language[defaultLanguage].condividi
      : language[defaultLanguage].condividi_lista;

    return (
      <div
        style={shareButtonStyle}
        onClick={() => {
          if (this.props.groceryList.list.length === 0) {
            return;
          } else {
            this.openModal();
          }
        }}
      >
        <div
          style={styles.shareBtn(serverResponse.config.secondary_color)}
          className="shareButton d-flex align-items-center justify-content-center w-100"
        >
          <CondividiNew style={{ width: "35px" }} />

          <span>{share_label}</span>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.closeModal()}
          centered={true}
          style={{ height: "200px" }}
          className="modal_share_custom"
        >
          <ModalHeader toggle={() => this.closeModal()}>
            {language[defaultLanguage].condividi_con}...
          </ModalHeader>
          <ModalBody style={{ height: "200px" }}>
            <Row
              className="custom-share-container"
              style={{ alignItems: "center" }}
            >
              <FacebookShareButton
                className="facebookShareButton shareIcon"
                url={link}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>

              <TwitterShareButton
                className="twitterShareIcon shareIcon"
                title={title}
                url={link}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>

              <TelegramShareButton
                className="telegramShareIcon shareIcon"
                title={title}
                url={link}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <TelegramIcon size={32} round={true} />
              </TelegramShareButton>

              <WhatsappShareButton
                className="whatsappShareIcon shareIcon"
                title={title}
                url={link}
                style={{ display: wa1 }}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>

              <span
                style={{ display: wa2 }}
                onClick={() => this.openReactNativeShare()}
              >
                <WhatsappIcon size={32} round={true} />
              </span>

              <div
                onClick={(e) => {
                  e.preventDefault();
                  this.exportGroceryList("email");
                }}
                aria-label="email"
                role="button"
                tabIndex="0"
                className="SocialMediaShareButton SocialMediaShareButton--email emailShareIcon shareIcon"
              >
                <div style={{ width: "32px", height: "32px" }}>
                  <svg
                    viewBox="0 0 64 64"
                    width="32"
                    height="32"
                    className="social-icon social-icon--email "
                  >
                    <g>
                      <circle cx="32" cy="32" r="31" fill="#7f7f7f"></circle>
                    </g>
                    <g>
                      <path
                        d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z"
                        fill="white"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              {/* <EmailShareButton
                title="Invia Email"
                iconFillColor="red"
                className="emailShareIcon shareIcon"
                url={link}
                bgStyle={{ fill: "red" }}
                beforeOnClick={() => {
                  this.exportGroceryList();
                }}
              >
                <EmailIcon
                  title="Invia Email"
                  bgStyle={{ fill: "red" }}
                  size={32}
                  round={true}
                />
              </EmailShareButton> */}

              <div
                className="copyButton shareIcon"
                onClick={() => {
                  this.exportGroceryList();
                  copyToClip();
                }}
                style={{
                  background: "#d94a4a",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "360px",
                  width: "32px",
                  height: "32px"
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: 0 }}
                  color="white"
                  size="sm"
                  icon={faCopy}
                />
              </div>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList
  };
};

export default connect(mapStateToProps, null)(ShareGroceryList);
