import axios from "axios";
import { serverResponse } from "../util/fakeServer";

const url = `${process.env.REACT_APP_ENDPOINT}/user/`;

const API = axios.create({
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

API.interceptors.request.use(
  (req) => {
    req.headers.clientId = `${serverResponse.config.client_id}`;
    req.headers.signboardId = `${serverResponse.config.signboard_id}`;
    return req;
  },
  (err) => Promise.reject(err)
);

export const resetPassword = (data) => API.post("reset-password", data);
export const setPassword = (data) => API.post("set-password", data);
