import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const LinkEsterno = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff"
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <path d="M34.09 28.55 28.55 23 23 28.55" className="cls-3" />
    <path d="M28.55 23v9.2a5.69 5.69 0 0 0 5.69 5.69" className="cls-3" />
    <rect
      width={9.64}
      height={20.36}
      x={47.88}
      y={22.16}
      className="cls-3"
      rx={4.82}
      transform="rotate(45 52.695 32.337)"
    />
    <rect
      width={9.64}
      height={20.36}
      x={27.52}
      y={42.52}
      className="cls-3"
      rx={4.82}
      transform="rotate(45 32.339 52.705)"
    />
    <path d="M48.87 36.14 36.99 48.02" className="cls-3" />
  </svg>
);
export default LinkEsterno;
