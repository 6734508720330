import Leaflet from "views/Leaflet";
import Categories from "../views/Categories.jsx";
import Pages from "views/Pages";
import Indice from "views/Indice";
import { serverResponse } from "../util/fakeServer";

let dashboardRoutes = [];

if (serverResponse.config.has_pages) {
  dashboardRoutes = [
    { path: "/products/:item_id?", component: Leaflet },
    { path: "/volantino", component: Leaflet },
    { path: "/leaflet/:index?", component: Leaflet },
    { path: "/categories/:name?", component: Categories },
    { path: "/pages", component: Pages },
    { path: "/indice", component: Indice }
  ];
} else {
  dashboardRoutes = [
    { path: "/volantino", component: Leaflet },
    { path: "/products/:item_id?", component: Categories },
    { path: "/leaflet/:index?", component: Leaflet },
    { path: "/categories/:name?", component: Categories },
    { path: "/pages", component: Pages }
  ];
}

// serverResponse.leaflet.pages.forEach(page => {
//   page.products.forEach(product => {
//     dashboardRoutes.push({ path: "/products/" + product.item_id, component: Leaflet});
//   });
// });

export default dashboardRoutes;
