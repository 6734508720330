import * as React from "react";

const Video = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path d="m49.69 41.44-12.95-8.63v19.42l12.95-10.79z" className="cls-3" />
    <rect
      width={59.11}
      height={52.52}
      x={12.97}
      y={16.26}
      className="cls-3"
      rx={1.03}
    />
    <path
      d="M14 16.26h13.55v52.52h0H14a1 1 0 0 1-1-1V17.26a1 1 0 0 1 1-1ZM57.48 16.26h13.59a1 1 0 0 1 1 1v50.52a1 1 0 0 1-1 1H57.48h0V16.26h0ZM12.97 33.77h14.58M12.97 51.27h14.58M57.48 33.77h14.59M57.48 51.27h14.59"
      className="cls-3"
    />
  </svg>
);
export default Video;
