import * as React from "react"
import { VOLANTINO_PRIMARY } from "../../../helper/utility"

const Ricetta = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <g id="chef_hat" data-name="chef hat">
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
      <path
        d="M59.92 46.7a13.39 13.39 0 1 0 0-26.77 13.06 13.06 0 0 0-4 .67 14.71 14.71 0 0 0-26.86 0 13.08 13.08 0 0 0-4-.67A13.39 13.39 0 1 0 36.6 40.1"
        className="cls-3"
      />
      <path
        d="m62.6 46.37-2.51 24.28a2.68 2.68 0 0 1-2.67 2.5H24.94a2.68 2.68 0 0 1-2.67-2.5l-2.51-24.28"
        className="cls-3"
      />
    </g>
    <path d="m35.93 64.7-.98-12.03M49.1 64.7l.99-12.03" className="cls-3" />
  </svg>
)
export default Ricetta
