import axios from "axios";
import { serverResponse } from "../util/fakeServer";

// console.log("THIS IS COOKIE", cookie);
// axios.defaults.headers.common["Cookie"] = "session_id" + cookie;

// const url = "https://viewmanager.interattivo.net/api/user/";

const url = `${process.env.REACT_APP_ENDPOINT}/user/`;

const API = axios.create({
  withCredentials: true,
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

API.interceptors.request.use(
  (req) => {
    req.headers.clientId = `${serverResponse.config.client_id}`;
    req.headers.signboardId = `${serverResponse.config.signboard_id}`;
    return req;
  },
  (err) => Promise.reject(err)
);

export const getCookie = (data) => API.get("get-cookie");
export const setCookie = (data) => API.get("set-cookie");

export const login = (data) => API.post("login", data);
export const signup = (data) => API.post("signup", data);
export const getProfile = (data) =>
  API.post("init", {
    signboard_id: serverResponse.config.signboard_id,
    only_logged: false,
    code: data
  });

export const logout = () =>
  API.post("logout", {
    signboard_id: serverResponse.config.signboard_id
  });

export const testProduct = () =>
  API.post("https://viewmanager.interattivo.net/api/product/test");
