import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const SchedaTecnica = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "white"
      }}
    />
    <path
      d="M34.81 18.85h15.42a2 2 0 0 1 2 2v4.48h0-19.42 0v-4.48a2 2 0 0 1 2-2Z"
      className="cls-3"
    />
    <path d="M56.55 23.16h4.32v38.72h-36.7V23.16h4.32" className="cls-3" />
    <path
      d="m28.49 37.12 4.32 4.32 6.47-6.48M45.76 39.28h8.63M28.49 45.76l4.32 4.32 6.47-6.48M45.76 47.92h8.63"
      className="cls-3"
    />
  </svg>
);
export default SchedaTecnica;
