import React from "react";
import RudPrivacyPolicy from "../components/common/RudPrivacyPolicy";
import { defaultLanguage } from "../helper/utility";
import { language } from "../languageProvider/language/language";
import DefaultManager from "./Default.manager";

class RudManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getComponentCategory() {
    return "CategoriesDynamic";
  }

  isSearchProductVisible() {
    return true;
  }

  noAddCart() {
    return true;
  }

  getVisibleInteractivities() {
    return [
      "plus",
      "world",
      "internal_link",
      "external_link",
      "play",
      "attachment"
    ];
  }

  getTitleType() {
    return {
      plus: language[defaultLanguage].dettaglio,
      world: "Link",
      attachment: language[defaultLanguage].manuale,
      play: language[defaultLanguage].video
    };
  }
  getDefaultFont() {
    return "Frutiger, sans-serif";
  }

  applyFilterOnMarker() {
    return true;
  }

  getIvaLabel() {
    return language[defaultLanguage].iva_esclusa;
  }

  getProdottiLabel(label) {
    if (label === "Volantino") return "Listino prezzi";
    if (label === "Vai a prodotti" || label === "Prodotti")
      return "Misure pneumatici";
    return label;
  }

  getPrivacyText() {
    return <RudPrivacyPolicy />;
  }

  forceVisit() {
    return false;
  }
}

export default RudManager;
