import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const ProdottoMano = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff"
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <g id="point_hand">
      <path
        d="M47.2 43.46V34.1a3.75 3.75 0 0 0-3.74-3.74h-1.88v13.1M52.81 37.84a3.75 3.75 0 0 0-3.74-3.74H47.2M58.42 47.2v-5.62a3.75 3.75 0 0 0-3.74-3.74h-1.87v5.62"
        className="cls-3"
      />
      <path
        d="M58.42 47.2c0 7.48-3.74 9.35-3.74 16.83H34.1v-1.87c0-7.48-7.48-11.22-7.48-15V34.1a1.87 1.87 0 0 1 1.87-1.87h0A3.75 3.75 0 0 1 32.23 36v5.61a1.88 1.88 0 0 0 1.87 1.88h0a1.88 1.88 0 0 0 1.9-1.91V23.81a2.81 2.81 0 1 1 5.61 0v6.55"
        className="cls-3"
      />
    </g>
  </svg>
);
export default ProdottoMano;
