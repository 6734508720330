import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const Video2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff"
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <path d="m49.1 42.52-11.28-8.47v16.93l11.28-8.46z" className="cls-3" />
    <rect
      width={43.26}
      height={33.86}
      x={20.89}
      y={25.59}
      className="cls-3"
      rx={1.03}
    />
    <path
      d="M21.89 25.59h8.41v33.86h0-8.41a1 1 0 0 1-1-1V26.59a1 1 0 0 1 1-1ZM54.75 25.59h8.41a1 1 0 0 1 1 1v31.86a1 1 0 0 1-1 1h-8.41 0V25.59h0ZM20.89 36.88h9.4M20.89 48.16h9.4M54.75 36.88h9.4M54.75 48.16h9.4"
      className="cls-3"
    />
  </svg>
);
export default Video2;
