import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const Curiosita = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <defs></defs>
    <circle
      cx={42.52}
      cy={42.52}
      r={38.12}
      style={{
        fill: "#fff"
      }}
    />
    <circle
      cx={42.52}
      cy={42.52}
      r={34.15}
      style={{
        fill: "#fff",
        stroke: VOLANTINO_PRIMARY
      }}
    />
    <path
      d="M35.34 36.94h-8a3.19 3.19 0 0 0-3.19 3.19V51.3a3.19 3.19 0 0 0 3.19 3.19h14.38l4.79 4.78V46.51"
      className="cls-3"
    />
    <path
      d="M57.68 43.32H43.32l-4.79 4.78V29a3.19 3.19 0 0 1 3.19-3.19h16A3.19 3.19 0 0 1 60.87 29v11.13a3.19 3.19 0 0 1-3.19 3.19Z"
      className="cls-3"
    />
    <circle cx={44.91} cy={33.74} r={0.8} className="cls-3" />
    <circle cx={49.7} cy={33.74} r={0.8} className="cls-3" />
    <circle cx={54.49} cy={33.74} r={0.8} className="cls-3" />
  </svg>
);
export default Curiosita;
