export const styles = {
  buttons_wrapper: {
    zIndex: 2,
    display: "flex"
  },
  button: {
    height: "38px",
    marginRight: "5px"
  }
};
