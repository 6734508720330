import * as React from "react"
const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Livello_1"
    x={0}
    y={0}
    style={{
      enableBackground: "new 0 0 184.9 184.9",
    }}
    viewBox="0 0 184.9 184.9"
  >
    <circle
      cx={92.5}
      cy={92.5}
      r={90.4}
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M92.5 184.3C41.8 184.3.6 143.1.6 92.4S41.8.5 92.5.5s91.9 41.2 91.9 91.9c-.1 50.7-41.3 91.9-91.9 91.9zm0-180.7c-49 0-88.9 39.9-88.9 88.9 0 49 39.9 88.9 88.9 88.9s88.9-39.9 88.9-88.9c0-49.1-39.9-88.9-88.9-88.9z"
      className="st1"
    />
    <path
      d="M133.8 135.3c-.4 0-.8-.1-1-.4L50.1 52.1c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l82.7 82.7c.6.6.6 1.5 0 2.1-.3.4-.7.5-1.1.5z"
      className="st1"
    />
    <path
      d="M51.1 135.3c-.4 0-.8-.1-1-.4-.6-.6-.6-1.5 0-2.1l82.7-82.7c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-82.7 82.7c-.3.3-.7.4-1.1.4z"
      className="st1"
    />
  </svg>
)
export default Close
