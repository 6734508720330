import React from "react";
import { serverResponse } from "../../util/fakeServer";
const LoaderModal = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        className="spinner-grow "
        style={{
          width: "5rem",
          height: "5rem",
          color: serverResponse.config.secondary_color
        }}
        role="status"
      />
    </div>
  );
};

export default LoaderModal;
