import * as React from "react";

const Volantino = ({ stroke = "#9d9d9c", fill = "none", strokeWidth = 2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_1"
    data-name="Livello 1"
    viewBox="0 0 85.04 85.04"
  >
    <path
      d="M16.26 26.41c7.16-4.78 19.1-4.78 26.26 0v35.8c-2.39-4.77-21.48-4.77-26.26 0Z"
      className="cls-1"
    />
    <path
      d="M68.78 26.41c-7.16-4.78-19.1-4.78-26.26 0v35.8c2.39-4.77 21.48-4.77 26.26 0Z"
      className="cls-1"
    />
  </svg>
);
export default Volantino;
