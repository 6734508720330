import React from "react";
import {
  getValue,
  scrollToSlide,
  sendAnalyticsEvent
} from "../../../helper/utility";
import { serverResponse } from "../../../util/fakeServer";
import { iconsObj } from "../../../helper/utility";
import { connect } from "react-redux";
import { openInteractivityModal } from "../../../redux/modal/action";
import {
  setIndexCarousel,
  setVerticalPages
} from "../../../redux/carousel/action";
import { BREAKPOINT } from "../../../helper/constant";
import useCurrentWidth from "../../../hooks/widthHook";
import ManagerFactory from "../../../managers/ManagerFactory";
import { useHistory } from "react-router-dom";
const manager = ManagerFactory.getManager();

const Marker = (props) => {
  const width = useCurrentWidth();
  const history = useHistory();
  // CONTROLLO SE è UN'INTERATTIVITà VIDEO
  if (props.info.type === "video" && !props.markerInfo.data.open_modal) {
    if (props.markerInfo.data.video_type === "youtube") {
      return (
        <iframe
          title={props.markerInfo.data.link}
          width="100%"
          height="100%"
          src={
            "https://www.youtube.com/embed/" +
            props.markerInfo.data.link +
            "?autoplay=1&mute=1"
          }
        />
      );
    }
    return (
      <div id="videoIper" style={{ height: "100%" }}>
        <video
          autoPlay="autolplay"
          defaultmuted="true"
          muted
          playsInline
          loop
          style={{ width: "100%" }}
        >
          <source src={props.markerInfo.data.link} />
        </video>
      </div>
    );
  }

  const link_markers = [
    "world",
    "internal_link",
    "external_link",
    "attachment"
  ];

  const sendAnalytics = () => {
    sendAnalyticsEvent(
      iconsObj[props.type].gaLabel,
      String(props.info.item_id)
    );
  };

  const detectRouter = (link) => {
    if (!link || typeof link !== "string") return null;

    if (false /*link.includes(serverResponse.leaflet.publication_url)*/) {
      const parts = link.split("/");
      if (parts) {
        const route = parts.pop();
        if (route) history.replace(route);
      }
    } else {
      window.open(link, "_blank");
    }
  };

  const link_action = () => {
    switch (props.markerInfo.data.link_type) {
      case "email":
        window.open("mailto:" + props.markerInfo.data.link, "_blank");
        break;
      case "telephone":
        window.open("tel:" + props.markerInfo.data.link);
        break;
      default:
        const openUrl =
          typeof props.markerInfo.data.link === "function"
            ? props.markerInfo.data
            : props.markerInfo.data.link;

        detectRouter(openUrl);

        break;
    }
  };

  const styleMarker = props.from_product
    ? {
        borderRadius: "100%",
        width: "40px",
        height: "40px",
        paddingLeft: 0,
        paddingRight: 0,
        margin: "0 !important"
      }
    : {};

  return (
    <div
      className={`marker ${
        serverResponse.config.iconAnimation
      } ${manager.getMarkerIconClass()} ${props.from_product &&
        "btn btn-primary  text-center"}`}
      style={styleMarker}
      type={props.type}
    >
      <div
        id={`${props.type}-${props.info.item_id}`}
        className={`icon-marker ${
          props.markerInfo.data.show_icon === false ? "d-none" : ""
        }`}
        onClick={() => {
          if (props.carouselIndex.zoomValue <= 1.2) {
            sendAnalytics();
            if (!link_markers.includes(props.type)) {
              let id_code =
                props.type === "plus" ? props.info.skul : props.info.item_id;
              props.openInteractivityModal(props, id_code);
            } else {
              switch (props.type) {
                case "world":
                case "external_link":
                case "attachment":
                  return link_action();
                default:
                  props.setIndexCarousel(
                    getValue(props.markerInfo.data.page_number)
                  );
                  if (
                    (serverResponse.config.scrollVertical ||
                      serverResponse.config.scrollHorizontal) &&
                    width < BREAKPOINT.lg
                  ) {
                    scrollToSlide(
                      props.markerInfo.data.page_number,
                      props.setVerticalPages,
                      props.carouselIndex.verticalPages
                    );
                    if (
                      (serverResponse.config.scrollVertical ||
                        serverResponse.config.scrollHorizontal) &&
                      width < BREAKPOINT.lg
                    ) {
                      scrollToSlide(
                        props.markerInfo.data.page_number,
                        props.setVerticalPages,
                        props.carouselIndex.verticalPages
                      );
                    }
                  }
              }
            }
          }
        }}
      >
        {!manager.applyFilterOnMarker() ? (
          <div className={props.custom_class}>
            {iconsObj[props.type].component}
          </div>
        ) : (!props.from_leaflet ||
            props.type === "plus" ||
            props.info.type !== "product") &&
          manager.applyFilterOnMarker() ? (
          <div className={props.custom_class}>
            {iconsObj[props.type].component}
          </div>
        ) : null}
      </div>

      {/* {Icone[iconsObj[props.type].component]} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps, {
  openInteractivityModal,
  setIndexCarousel,
  setVerticalPages
})(Marker);
