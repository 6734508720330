import React from "react";

const TitleModal = ({ title, fontSize = "30px", lineHeight = "32px" }) => {
  return (
    <p
      dangerouslySetInnerHTML={{
        __html: title
      }}
      style={{
        fontSize: fontSize,
        // textTransform: "uppercase",
        fontWeight: "bold",
        fontFamily: "var(--font-family)",
        lineHeight: lineHeight
      }}
    />
  );
};

export default TitleModal;
