import * as React from "react"
const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_2"
    data-name="Livello 2"
    viewBox="0 0 32.25 32.25"
    {...props}
  >
    <g id="Livello_1-2" data-name="Livello 1">
      <path d="M16.12.77v30.71M.77 16.12h30.71" className="cls-1" />
    </g>
  </svg>
)
export default Plus
