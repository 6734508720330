import axios from "axios";
import { serverResponse } from "../util/fakeServer";

const url = `${process.env.REACT_APP_ENDPOINT}/grocery-list/`;

const API = axios.create({
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

API.interceptors.request.use(
  (req) => {
    req.headers.clientId = `${serverResponse.config.client_id}`;
    req.headers.signboardId = `${serverResponse.config.signboard_id}`;
    return req;
  },
  (err) => Promise.reject(err)
);

export const getPDF = (data) =>
  API.post("get-pdf", data, { responseType: "blob" });

export const getCSV = (data) =>
  API.post("get-csv", data, { responseType: "blob" });
