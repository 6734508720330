import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const Carosello = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_2"
    data-name="Livello 2"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <g id="Livello_1-2" data-name="Livello 1">
      <circle
        cx={42.52}
        cy={42.52}
        r={38.12}
        style={{
          fill: "#fff",
          stroke: VOLANTINO_PRIMARY
        }}
      />
      <circle
        cx={42.52}
        cy={42.52}
        r={34.15}
        style={{
          fill: "#fff",
          stroke: VOLANTINO_PRIMARY
        }}
      />
      <path
        d="M26.51 26.51h32.03v32.03H26.51zM63.25 29.23h4v26.08h-4M22.03 55.31h-4V29.23h4"
        className="cls-3"
      />
      <path
        d="M31.62 51.67h22.67l-6.67-12.89-6.22 7.7-4-4.29-5.78 9.48z"
        className="cls-3"
      />
      <circle cx={37.1} cy={35.48} r={2.3} className="cls-33" />
      <path
        d="M0 0h85.04v85.04H0z"
        style={{
          fill: "none"
        }}
      />
    </g>
  </svg>
);
export default Carosello;
